import React, { useState } from 'react';
import { Container, Col, Row } from "react-bootstrap"; 
import ReviewSection from '../ReviewSection/ReviewSection';
 import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
import './assets/styles/_index.scss';
 

const ReviewsCarouselCulture = () => { 
    return (
        <section className="reviews-carousel-wrapper">
           <Container>
                <Row> 
                    <Col>
                        <div className="reviews-wrapper text-center">
                            <section className="widget_module">
                                <div className="elfsight-app-96fc9830-a1ab-4791-84bc-c9e4b3e7e715"></div>
                            </section> 
                            <div className="d-md-flex justify-content-left align-items-center reviews-rated-wrapper">
                                <ReviewSection />
                            </div>
                        </div>
                    </Col>
                </Row>
           </Container> 
        </section>
    )
}

export default ReviewsCarouselCulture