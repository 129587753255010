import React from "react"
import { Container } from "react-bootstrap"
import GetLink from "../functions/GetLink"
import "./ValuationLanding.scss"

const ValuationLanding = ({ module }) => {
  return (
    <div className="valuation-landing-wrapper section-m120">
      <Container className="valuation-landing-container">
        {module.Box_Items?.map((box, i) => (
          <>
            <div className="valuation-box">
              <div className="illustration">
                <img src={box.Box_Image.url} alt="valuation-illustration" />
              </div>
              <div className="content">
                <h5 className="title">{box.Box_Title}</h5>
                <p className="description">{box.Box_Description}</p>
              </div>
              <div className="cta-section">
                <GetLink
                  link={box.Box_CTA_Link}
                  label={box.Box_CTA_Label}
                  className="btn btn-fill-primary"
                />
              </div>
            </div>
            {i === 0 && (
              <>
                <div className="horizontal-divider d-block d-md-none" />
                <div className="vertical-divider d-none d-md-block" />
              </>
            )}
          </>
        ))}
      </Container>
    </div>
  )
}

export default ValuationLanding
