import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { getCommaSeparatedValue } from "../utils/utils";
import CustomCurrencyInput from "./MovingCostCalculator/CustomCurrencyInput";

const RentAffordabilityCalculator = () => {
  const [key, setKey] = useState("monthly");

  const Content = ({ label, rtitle, rtest, initValue, type }) => {
    const [value, setValue] = useState(initValue);

    const getValue = (val) => {
      let commaVal = val.toString().replace(",", "");
      if (type === "monthly") {
        commaVal = commaVal * 30;
      }
      if (type === "annual") {
        commaVal = commaVal / 30;
      }

      return getCommaSeparatedValue(commaVal);
    };

    const [resultValue, setResultValue] = useState(getValue(value));

    const handleOnValueChange = (value, _, values) => {
      if (!value) {
        setValue(0);
        setResultValue(getValue("0"));
        return;
      }

      if (Number.isNaN(Number(value))) {
        return;
      }

      setValue(value);
      setResultValue(getValue(value));
    };

    return (
      <>
        <div className="input-section">
          <label htmlFor="rent-aff-input">{label}</label>
          <div class="input-box">
            <span class="prefix">£</span>
            <CustomCurrencyInput
              id="rent-aff-input"
              name="rent-aff-input"
              className="currency-input-field"
              value={value}
              onValueChange={handleOnValueChange}
              placeholder={value}
            />
          </div>
        </div>
        <div className="result-section">
          <p className="result-title">{rtitle}</p>
          <p className="result">{`£${resultValue} ${rtest}`}</p>
        </div>
      </>
    );
  };

  return (
    <div className="rent-offordability-calculator">
      <div className="text-sm">Do you want to base it on your income or the monthly rent?</div>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="tab-section"
        fill
      >
        <Tab eventKey="monthly" title="Monthly Rent">
          <Content
            label="Monthly Rent"
            rtitle="You need an income of"
            rtest={`per year.`}
            initValue="600"
            type="monthly"
          />
        </Tab>
        <Tab eventKey="annual" title="Annual Income">
          <Content
            label="Annual Income"
            rtitle="You can afford rent of"
            rtest={`per month.`}
            initValue="39000"
            type="annual"
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default RentAffordabilityCalculator;
