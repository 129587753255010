import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { Link } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { AllPartners } from "../../queries/common_use_query"
import GetGGFXImage from "../common/site/get-ggfx-image"
import noImg from "../../images/no-image.png"
import { TeamBaseURL } from "../common/site/utils"

const CulturePartnerList = () => {
  const {
    loading: loading,
    error: error,
    data: data,
  } = AllPartners()

  if (!data) return null

  // Slider settings
  let settings = {
    dots: false,
    arrows: true,
    infinite: data.teams.length > 4,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: data.teams.length > 4,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: data.teams.length > 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  }
  // Slider settings
  const ImageRenderList = ({ item, processedImages }) => {
    return (
      item.Image ? <GetGGFXImage
        imagename={"teams.Image.tileimg"}
        imagesource={item.Image}
        fallbackalt={item.Name + " - " + item.Designation}
        imagetransformresult={processedImages}
        id={item.id}
      /> : <img src={noImg} alt={item.Name + " - " + item.Designation} />
    );
  };

  return (
    <section className=" latest-news-wrapper news-listing team-listing"> 
        <Row>

          <div className="news-listing-wrapper">
            <Slider className="latest-news-slider" {...settings}>
              {data?.teams?.length > 0 &&
                data.teams.map((item, index) => {
                  let processedImages = JSON.stringify({});
                  if (item?.imagetransforms?.Image_Transforms) {
                    processedImages = item.imagetransforms.Image_Transforms;
                  }
                  return (
                    <>

                      <div className=" latest-news-slide news-item-wrap">
                        <div className="img-zoom rounded">
                          <Link to={TeamBaseURL + item.URL + "/ "}>
                            <ImageRenderList processedImages={processedImages} item={item} />
                          </Link>
                        </div>
                        <div className="news-title">
                          <Link to={TeamBaseURL + item.URL + "/ "}>{item.Name}</Link>
                        </div>
                        <div className="news-date">{item.Designation}</div>
                      </div>

                    </>
                  );
                })}
            </Slider>
          </div>

        </Row> 
    </section>
  )
}

export default CulturePartnerList
