import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { Link } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./assets/styles/_index.scss"
import { RecentNews, RecentEvents } from "../../queries/common_use_query"
import GetGGFXImage from "../common/site/get-ggfx-image"
import { NewsBaseURL } from "../common/site/utils"
import moment from "moment";
import { useLocation } from "@reach/router"
import _ from 'lodash';

const LatestNewsCarousel = ({ type }) => {
  const {pathname} = useLocation();
  const {
    loading: loading,
    error: error,
    data: data,
  } = type === "Events_Listing_Module" ? RecentEvents() : RecentNews()

  if (!data) return null
 
  //   function removeTags(str) {
  //     if (str === null || str === "") return false
  //     else str = str.toString()
  //     return str.replace(/(<([^>]+)>)/gi, "")
  //   }

  function limit(string = "", limit = 0) {
    return string.substring(0, limit)
  }

  // Slider settings
  let settings = {
    dots: false,
    arrows: true,
    infinite: data.newsInsights.length > 2,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: data.newsInsights.length > 2,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: data.newsInsights.length > 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  }
  // Slider settings

  const heading =
    type === "Events_Listing_Module"
      ? "Latest news at David Astburys."
      : "Our passion for London homes, working from our offices in Crouch End and Islington, means that we’re always keeping a close eye on industry news and insights to provide the best service and support for our clients."

  const description =
    type === "Events_Listing_Module" ? (
       "Unveiling the latest trends and developments in the community and real estate."   
    ) 
    : (
      "Browse our latest news stories and blog posts penned by our in-house experts — they may just inspire your next move."
    )

  return (
    <section className="latest-news-wrapper section-p120">
      <Container>
        <div className="justify-content-center text-center latest-news-header">
          
            <h2>{heading}</h2>
            <p>{description}</p>
          
        </div>
        <Row>
          <Col>
            <Slider className="latest-news-slider" {...settings}>
              {data?.newsInsights?.length > 0 &&
                data.newsInsights
                  .slice()
                  .sort((a, b) => new Date(b.News_Date) - new Date(a.News_Date))
                  .map((item, index) => {
                    //console.log("item",item)
                    let processedImages = JSON.stringify({})
                    if (item?.imagetransforms?.Tile_Image_Transforms) {
                      processedImages =
                        item.imagetransforms.Tile_Image_Transforms
                    }
                    var limtnewsdesc =
                      item.Description.length > 120
                        ? limit(item.Description, 120) + "..."
                        : item.Description

                        let categoryNames = ""
                        if(!_.isEmpty(item?.select_categories)) {
                            categoryNames = item.select_categories.map((cat, index) => {
                                return <span key={index}>{cat.Name}{index < item.select_categories.length - 1 ? ", " : ""}</span>
                            })
                        }

                    return (
                      <>
                        <div className="latest-news-slide">
                          <div className="latest-news-img-zoom">
                            <Link to={NewsBaseURL + item.URL + "/ "}>
                              <GetGGFXImage
                                imagename={"news-insights.Tile_Image.tileimg"}
                                imagesource={item.Tile_Image}
                                fallbackalt={item.Title}
                                imagetransformresult={processedImages}
                                id={item.id}
                              />
                            </Link>
                          </div>
                          <div className="latest-news-details">
                          {categoryNames && <div className='news-cat'>{categoryNames}</div>}
                            <div className="latest-news-title">
                              <Link to={NewsBaseURL + item.URL + "/ "}>
                                {item.Title}
                              </Link>
                            </div>
                            <div className='news-date'>{moment(item?.News_Date).format("Do MMMM YYYY")}</div>
                            <p className="latest-news-desc">{limtnewsdesc}</p>
                          </div>
                        </div>
                      </>
                    )
                  })}
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default LatestNewsCarousel
