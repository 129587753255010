import React,{useState} from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router"
import GetGatsbyImage from '../common/site/get-gatsby-image';
import PlayVideo from "../PlayVideo/PlayVideo";

import "../CultureReviewModule/CultureReviewModule.scss";

const CultureReviewModule = (props) =>{
    const {pathname} = useLocation()
    const [isPlay, setPlay] = useState(false);
    const [videourl, setVideourl] = useState(false);
    const [videotour, setVideoTour] = useState(false);
    console.log("props",props)
    const videoblocks = props.module;
    return(
        pathname =="/our-culture/" &&
        <>
        <Helmet>
          <script src="https://apps.elfsight.com/p/platform.js" type="text/javascript" async="true"></script>
      </Helmet>

      <div className="culture-review-module">
            <Container>
                <Row>
                            <Col xs={12} md={6}>
                            <div className='video-block-wrapper'>
                                                <div className='video-img rounded img-zoom'>
                                                    <GetGatsbyImage
                                                        image={videoblocks?.Video_Thumbnail}
                                                        fallbackalt={`sampleimage`}
                                                    />
                                                    <a className='btn-play' href="javascript:void(0)" onClick={(e) => { setPlay(true); setVideourl(videoblocks.Youtube_Video_URL); setVideoTour(false); }}><i className="icon icon-play"></i></a>
                                                </div>
                            </div>  
                        </Col>

                        <Col xs={12} md={6} className="elfsight-culture-review-module">
                          {/* <div className="elfsight-culture-review-module">
                             < div class="elfsight-app-de524606-e9f0-4c92-bc18-aff05b2c608a"></div>
                          </div> */}
                           < div class="elfsight-app-de524606-e9f0-4c92-bc18-aff05b2c608a"></div>
                        </Col>
                
            
                </Row>
            </Container>
      </div>
      
      {isPlay && videourl &&
                <PlayVideo isOpen={isPlay} stopPlay={setPlay} videoId="" isCloseFunction={setPlay} videourl={videourl} htmlink={""} />
            }
      
      </>
    );
}
export default CultureReviewModule;