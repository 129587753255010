import React from "react"
import { Container, Col, Row, Form, Button } from "react-bootstrap"
import { Link } from "gatsby"
import "./assets/styles/_index.scss"
import BookImg from "../../images/book-appointment.png"
import BookAppointmentForm from '../../components/forms/book-an-appointment'
const BookAnAppointment = () => {
  return (
    <section className="book-an-appointment-wrapper section-p">
      <Container>
        <Row className="align-items-center">
          <Col lg={6}>
            <div className="book-img-wrapper">
              <img src={BookImg} className="img-fluid" />
            </div>
          </Col>
          <Col lg={1}></Col>
          <Col lg={5}>
            <div className="book-details-wrapper">
              <div className="title-text-sm-2">Book an appointment</div>
              <h2>Let’s have a chat.</h2>
              <p>
                  Get in touch today - our team of friendly experts are ready to listen and answer all your questions and concerns.
              </p>
              <BookAppointmentForm />
              {/* <Form className="book-form">
                <Form.Group className="form-input-with-bg">
                  <Form.Control type="text" placeholder="Name" />
                </Form.Group>
                <Form.Group className="form-input-with-bg">
                  <Form.Control type="email" placeholder="Email Address" />
                </Form.Group>
                <Form.Group className="form-input-with-bg">
                  <Form.Control type="text" placeholder="Phone Number" />
                </Form.Group>
                <Button
                  variant=""
                  className="btn-white-outline w-100"
                  type="submit"
                >
                  Submit Details
                </Button>
                <Form.Text className="book-text-sm">
                  By clicking Submit, you agree to our{" "}
                  <Link
                    to="/terms-and-conditions/"
                    className="hover-border-white"
                  >
                    Terms & Conditions
                  </Link>{" "}
                  and{" "}
                  <Link to="/privacy-policy/" className="hover-border-white">
                    Privacy Policy
                  </Link>
                  .
                </Form.Text>
              </Form> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default BookAnAppointment
