import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import useCompanyInfo from "../../hooks/useCompanyInfo";
import { useLocation } from "@reach/router"
import "./SocialWidgetModule.scss";

const SocialWidget = ({ Select_Module }) => {
  const { InstaLink } = useCompanyInfo()
  const {pathname} = useLocation()
  return (
    <>
      <Helmet>
        <script src="https://apps.elfsight.com/p/platform.js" type="text/javascript" async="true"></script>
      </Helmet>
      {Select_Module === "Social_Widget_Module" && (
        <section className="latest-news-wrapper section-m120 insta-module-section">
          <Container>
            <div className="justify-content-center text-center latest-news-header">
              <h2>Get social with us.</h2>
              
              <p>Follow our latest trends and developments on our social wall.</p>
            </div>
            <Row>
              { pathname == "/our-culture/" ? 
                 <Col>
                 <div className="elfsight-app-9ec8fe9a-30e2-4d3a-9ddd-02179f30e6a7"></div>
               </Col> 
              :

                <Col>
                <div className="elfsight-app-9ec8fe9a-30e2-4d3a-9ddd-02179f30e6a7"></div>
              </Col>
              }
              
            </Row>
          </Container>
        </section>
      )}
      {Select_Module === "Reviews_Page_Widget" && (
        <section className="section-p120 widget_module reviews_bg">
          <Container>
            <div className="elfsight-app-4dd10dd9-4ba6-487b-917b-4b107585d523"></div>
          </Container>
        </section>
      )}
    </>
  );
};

export default SocialWidget;
