import React from "react";
import CurrencyInput from "react-currency-input-field";

const CustomCurrencyInput = (props) => {
  return (
    <CurrencyInput
      {...props}
      disableAbbreviations
      allowDecimals={false}
      maxLength={21}
      autocomplete="off"
    />
  );
};

export default CustomCurrencyInput;
