import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import RadioField from './elements/radio'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import $ from "jquery"
import axios from "axios"
import * as qs from "query-string"
import _ from "lodash";
import loadable from '@loadable/component'
import { OFFICES, VALUATION_OPTIONS, getAreaEmail, getOffice, getPostCode } from "./utils"

const LoqateAdress = loadable(() => import('./elements/address-search'))    

function MySimpleForm(props) {
  const Valpal = loadable(() => import('./elements/valpal-result'))

 
  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();
  const [radioval, setRadioval] = useState(VALUATION_OPTIONS[0].value);
  const [username, setUsername] = useState('')
  const [useremail, setUseremail] = useState('')
  const [userphone, setUserphone] = useState('')
  const [valtype, setValtype] = useState('')
  const [valbedrooms, setValbedrooms] = useState('')
  const [valPtype, setValPtype] = useState('')
  const [addressLabel, setAddressLabel] = useState('')
  const [addressBuildingName, setAddressBuildingName] = useState('')
  const [addressSecondaryStreet, setAddressSecondaryStreet] = useState('')
  const [addressBuildingNumber, setAddressBuildingNumber] = useState('')
  const [addressPostalCode, setAddressPostalCode] = useState('')
  const [addressSubBuilding, setAddressSubBuilding] = useState('')
  const [addressStreet, setAddressStreet] = useState('')



  const fields = ([
      {
        element: "config",
        formname: "Instant Valuation",
        form_type: "contact",
        error_text: "Highlighted fields are required",
        success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
        email_temp_user: "instant_valuation_user",
        email_temp_admin: "instant_valuation_admin",
        email_subject_user:"Instant Valuation",
        email_subject_admin:"Instant Valuation",
        email_server_func: "instant_valuation",
        event_tracking: "contact",
        page_url: "/general-enquiry"
      },   
      {
        grpmd: "12",
        label: "Type of Valuation",
        placeholder: "Type of Valuation",
        name: "enquiry",
        id: "enquiry",
        element: "radio",
        required: true,
        type: "select",
        class: "select-box",        
        values: VALUATION_OPTIONS
      },      
      {
        grpmd: "12",
        label: "Current Home Address",
        placeholder: "Enter your current home address here",
        name: "homeaddress",
        type: "text",
        element: "input",
        class:"form__field",
        required: true,
        labelClass: "content_b-18",
      },   
      {
        grpmd: "12",
        label: "Property Address",
        placeholder: "Start typing your address...",
        name: "address",
        type: "text",
        element: "address",
        class:"form__field",
        required: true,
        labelClass: "content_b-18",
      },
      {
        grpmd: "12",
        label: "Name",
        placeholder: "Enter your name here",
        name: "name",
        type: "text",
        element: "input",
        class:"form__field",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        labelClass: "content_b-18",
      },
      {
        grpmd: "12",
        label: "Email Address",
        placeholder: "Enter your email here",
        name: "email",
        type: "email",
        element: "input",
        class:"form__field",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        labelClass: "content_b-18",
      },
      {
        grpmd: "12",
        label: "Telephone Number",
        placeholder: "Enter your telephone number here",
        name: "telephone",
        type: "text",
        element: "input",
        class:"form__field",
        required: true,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "content_b-18",
      },   
      {
        grpmd: "12",
        label: "Property Type",
        placeholder: "Property Type",
        name: "propertytype",
        id: "propertytype",
        element: "select",
        required: true,
        type: "select",
        class: "select-box",
        values: ['Flat', 'Terraced House', 'Semi Detached House', 'Detached House', 'Semi Detached Bungalow', 'Detached Bungalow'],
      },
      {
        grpmd: "12",
        label: "Bedrooms",
        placeholder: "Bedrooms",
        name: "bedrooms",
        id: "bedrooms",
        element: "select",
        required: true,
        type: "select",
        class: "select-box",
        values: ['1', '2', '3', '4', '5', '6'],
    },
  
      {
        grpmd: "12",
        label: "Message",
        placeholder:"Write your message here...",
        name: "message",
        element: "textarea",
        class: "form__field form__field-textarea",
        rows:"5",
        labelClass: "content_b-18"
      },  
      {
        grpmd: "12",
        name: "Submit",
        type:"submit",
        element: "button",
        value: "submit",
        labelClass: "content_b-18"
      },
      
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }
  const getRadio = event => {
    event.target.value = event.target.value.trimStart()  
    setRadioval(event.target.value)
  }

  const handleselectchange = event => {
    // remove initial empty spaces
    event.value = event.value.trimStart()
  }
  
  useEffect(() => {
    $(".form-field-group input").blur(function(){
      $(".form-field-group input").each(function(){
        if ($(this).is(":invalid")) {
          $(this).closest(".form-field-group").addClass("invalid--field");
        } else {
          $(this).closest(".form-field-group").removeClass("invalid--field");
        }      
      })
    });
    // $(".select-option.form-field-group").change(function(){
    //   $(".select-option.form-field-group input[type=hidden]").each(function(){
    //     if ($(this).val() == "") {
    //       $(this).closest(".form-field-group").addClass("invalid--field");
    //     } else {
    //       $(this).closest(".form-field-group").removeClass("invalid--field");
    //     }      
    //   })
    // });

    if (token !== '') {

 const processFromData = async () => {
      
      formvalues['g-recaptcha-response'] = token;

      const propertyPostcode = getPostCode()
      const office = getOffice(propertyPostcode)
      
      let formData = new FormData();

      // formvalues['extra'] = JSON.stringify({
      //   message: formvalues.message,
      //   name: formvalues.name
      // });
      formvalues['name'] = formvalues.name;
      formvalues['email_subject_user'] = fields[0].email_subject_user;
      formvalues['email_subject_admin'] = fields[0].email_subject_admin;
      formvalues['valuation_department'] = radioval === "BOTH" ? "SALES" : radioval;
      formvalues['lead_comment'] = radioval === "BOTH" ? "Customer requested both a sales and lettings valuation" : "";
      formvalues['use_area_email'] = getAreaEmail(office, radioval);
      formvalues['enquiry'] = radioval

      formData.append('data', JSON.stringify(formvalues));

      postFormData(formData).then(async apiRes => {
      
      window.grecaptcha.reset()

      // lets send mail
      await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
        method: `POST`,
        mode: "no-cors",
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify(formvalues),
      })

      });

      const url = typeof window !== 'undefined' ? window.location.href : ''  
      if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
        fields[0].formname = 'Get mortgage help';
      }
      // tracking event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'form-'+fields[0].event_tracking,
        'formId': 'form-'+fields[0].event_tracking,
        'formName': fields[0].formname,
        'formLabel': radioval + " - " + fields[0].formname
      });

      setShowerror(false);
      setThankyou(true);
      $('.form-inst-val').hide();
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {
    const tempValType = $('input:radio[name=enquiry]:checked').val()
    // 
      setRadioval($("input[name=enquiry]:checked").val())
      $("input[name=enquiry]").val(radioval)
      setUsername ($("input[name=name]").val());
      setUseremail ($("input[name=email]").val());
      setUserphone ($("input[name=telephone]").val());
      setValtype(tempValType === "SALES" ? "sale" : tempValType === "LETTINGS" ? "let" : "both");
      setValbedrooms($("input[name=bedrooms]").val());
      setValPtype($("input[name=propertytype]").val());
      setAddressBuildingName($("input[name=addressBuildingName]").val());
      setAddressSecondaryStreet($("input[name=addressSecondaryStreet]").val());
      setAddressBuildingNumber($("input[name=addressBuildingNumber]").val());
      setAddressPostalCode($("input[name=addressPostalCode]").val());
      setAddressSubBuilding($("input[name=addressSubBuilding]").val());
      setAddressStreet($("input[name=addressStreet]").val());
    // 
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      $(".form-field-group input").each(function(){
        if ($(this).is(":invalid")) {
          $(this).closest(".form-field-group").addClass("invalid--field");
        } else {
          $(this).closest(".form-field-group").removeClass("invalid--field");
        }      
      })
      // $(".select-option.form-field-group input[type=hidden]").each(function(){
      //   if ($(this).val() == "") {
      //     $(this).closest(".form-field-group").addClass("invalid--field");
      //   } else {
      //     $(this).closest(".form-field-group").removeClass("invalid--field");
      //   }      
      // })
      
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? radioval : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;
      json['extra'] = _.omit(json, ["formname", "name", "email", "telephone"])

      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
      
    }
  };

  return (
    <div className="form stbform common_form_class form-instant-valuation" id="InstantValuation">
    <div ref={myRef} />

    {showerror && <div className="alert-error">
      <p>{fields[0].error_text}</p>
    </div>} 

    <div className="form-wraps input-wraps">


    {/* {showthankyou && <div className="alert-success">
      <p>{fields[0].success_text}</p>
    </div>} */}
      {showthankyou &&
      <>
      {/* <h2>Thank you {username}. Here are your estimated {valtype == "sale" ? "Sale" : valtype == "let" ? 'Rent': 'Sale and Rent'}  Values.</h2> */}
            <div className="valpal-list-api">
              <Valpal enqtype={valtype} addressBuildingName={addressBuildingName}  addressSecondaryStreet={addressSecondaryStreet} addressBuildingNumber={addressBuildingNumber} addressPostalCode={addressPostalCode} addressSubBuilding={addressSubBuilding} addressStreet={addressStreet} bedrooms={valbedrooms} propertytype={valPtype} name={username} phone={userphone} email={useremail} />
            </div>
      </>
      }
      <Form className="form-inst-val contact-form form-w-label" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="to_email_id" value={props.to_email_id} />
        <input type="hidden" name="bot-field" />
        {/* Loqate */}
          <input id="addressBuildingName" type="hidden" name="addressBuildingName" />
          <input id="addressSecondaryStreet" type="hidden" name="addressSecondaryStreet" />
          <input id="addressBuildingNumber"  type="hidden" name="addressBuildingNumber" />
          <input id="addressPostalCode"  type="hidden" name="addressPostalCode" />
          <input id="addressSubBuilding"  type="hidden" name="addressSubBuilding" />
          <input id="addressStreet"  type="hidden" name="addressStreet" />
          <input id="addressLabel" value="" type="hidden" name="addressLabel" />          
        {/* Loqate */}
        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  label={field.label}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                />
              );
            }
            if ( "address" === field.element ) {
              return (
                <LoqateAdress addressLabel={addressLabel} instantVal/>
              )
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handleselectchange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  label={field.label}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  label={field.label}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioField
                  label={field.label}
                  name={field.name}
                  ref={field.ref}
                  values={field.values}
                  labels={field.values}
                  fieldClass={field.class}
                  checked={field.checked}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={getRadio}
                  lastchild={field.lastchild}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                />
              );
            }
          })
        }      
      </Form>      
      </div>
    </div>
  );
}


const InstantValuation = (props) => (
    <MySimpleForm to_email_id={props.to_email_id} subject={props.subject} staff_name={props.staff_name} formtype={props.formtype} messagelabel={props.messagelabel} messageplaceholder={props.messageplaceholder} />
)

export default InstantValuation