import React from "react"
import buyOnly from "../../../images/icons/buy_only.svg"
import sellOnly from "../../../images/icons/sale_only.svg"
import buySell from "../../../images/icons/buy_and_sell.svg"

export const BuySVG = () => {
  return (
    <img src={buyOnly} alt="buy" />
  )
}

export const BuySellSVG = () => {
  return (
    <img src={buySell} alt="sell" />
  )
}

export const SellSVG = () => {
  return (
    <img src={sellOnly} alt="sell" />
  )
}
