import React from "react"
import { Container } from "react-bootstrap"
import "./AreaguidesListing.scss"
import { graphql, useStaticQuery } from "gatsby"
import AreaListingCard from "./AreaListingCard"

const AreaguidesListing = () => {
  const data = useStaticQuery(graphql`
    query GetAreaguides {
      glstrapi {
        areaguides {
          Tile_Image {
            url
          }
          imagetransforms
          URL
          Video_Link
          id
          Name
        }
      }
    }
  `)

  const areaguides = data.glstrapi.areaguides

  return (
    <div className="area-listing-wrapper section-m">
      <Container className="area-listing-container">
        {areaguides?.length > 0 &&
          areaguides.map(areaguide => (
            <AreaListingCard areaguide={areaguide} />
          ))}
      </Container>
    </div>
  )
}

export default AreaguidesListing
