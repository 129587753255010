import React, { useState } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PlayVideo from "../PlayVideo/PlayVideo";
import './assets/styles/_index.scss';
import ReviewImg from "../../images/review-img.png";
// import GoogleImg from "../../images/google-reviews.png";
import GoogleImg from "./greview.svg"
import { StaticImage } from 'gatsby-plugin-image';
import ReviewSection from '../ReviewSection/ReviewSection';

const ReviewsCarousel = () => {
    const [isPlay, setPlay] = useState(false);
    const [videourl, setVideourl] = useState(false);
    const [videotour, setVideoTour] = useState(false);

    // Slider settings
    let settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 2000,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
    }
    // Slider settings

    return (
        <section className="reviews-carousel-wrapper section-m120">
           <Container>
                <Row>
                    {/* <Col lg={6}>
                        <div className="reviews-video-wrapper">
                            <StaticImage src='../../images/reviewImg.jpg' className="img-fluid image-section"/>
                            <a onClick={(e) => { setPlay(true);setVideoTour(false);}}><i className="icon icon-play"></i></a>
                        </div>
                    </Col>
                    <Col lg={1}></Col> */}
                    <Col>
                        <div className="reviews-wrapper text-center">
                            <section className="widget_module">
                                <div className="elfsight-app-96fc9830-a1ab-4791-84bc-c9e4b3e7e715"></div>
                            </section>
                            <div className="d-md-flex justify-content-center align-items-center reviews-rated-wrapper">
                                <ReviewSection />
                            </div>
                        </div>
                    </Col>
                </Row>
           </Container>
           {isPlay &&
                <PlayVideo isOpen={isPlay} stopPlay={setPlay} videoId="" isCloseFunction={setPlay} videourl={"https://youtu.be/kvrHVIO6N08"} htmlink={""}/>
           }
        </section>
    )
}

export default ReviewsCarousel