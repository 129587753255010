import React from "react";
import ScreenLayout from "./ScreenLayout";
import { useSelector, useDispatch } from "react-redux";
import {
  setAgencyFee,
  setSellPrice,
  setSellConveyancingCosts,
} from "../../../../redux/features/movingCostCalculatorSlice";
import CustomCurrencyInput from "../CustomCurrencyInput";

const AgencyFeesScreen = () => {
  const dispatch = useDispatch();

  const sellPrice = useSelector((state) => state.movingCostCalculator.sellPrice);
  const agencyFee = useSelector((state) => state.movingCostCalculator.agencyFee);

  const onSellPriceChange = (value) => {
    if (!value) {
      dispatch(setSellPrice(0));
      dispatch(setAgencyFee(0));
      dispatch(setSellConveyancingCosts(600));
      return;
    }

    dispatch(setSellConveyancingCosts(Math.max(Math.ceil(value * 0.003), 600)));
    dispatch(setSellPrice(Number(value)));
    dispatch(setAgencyFee(Number(value * 0.015)));
  };

  return (
    <ScreenLayout title="Estate Agency Fees" classNames="other-costs-screen">
      <div className="input-section">
        <label htmlFor="sell_price">Price of property you are selling</label>
        <div class="input-box">
          <span class="prefix">£</span>
          <CustomCurrencyInput
            id="sell_price"
            name="sell_price"
            className="currency-input-field"
            value={sellPrice}
            onValueChange={onSellPriceChange}
            placeholder={""}
          />
        </div>
      </div>
      <div className="text-section-container">
        <div className="text-section">
          <p className="text-label input-label">Estate Agency Fees (1.5% inc VAT)</p>
          <p className="text-value">£{agencyFee}</p>
        </div>
      </div>
    </ScreenLayout>
  );
};

export default AgencyFeesScreen;
