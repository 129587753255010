import React from "react"
import { Container } from "react-bootstrap"
import GridBlock from "./GridBlock"
import "./GridBlocksModule.scss"

const GridBlocksModule = ({ module }) => {
  const blocks = module?.Tile_Grids
  const isWhiteBg = module?.Bg_Color === "White"

  return (
    <div
      className={`grid-block_wrapper${isWhiteBg ? "" : "-grey"} ${
        isWhiteBg ? "section-m" : "section-p"
      }`}
    >
      <Container className="grid-block-container">
        <div className="title-content">
          <h4 className="title">{module.Tile_Grid_Title}</h4>
          <h2 className="heading">{module.Tile_Grid_Heading}</h2>
        </div>
        <div className="grid-blocks">
          {blocks.map((block, i) => {
            return <GridBlock block={block} index={i + 1} />
          })}
        </div>
      </Container>
    </div>
  )
}

export default GridBlocksModule
