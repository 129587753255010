import React, { useState } from "react"
import { Container, Col, Row } from "react-bootstrap";
import parse from "html-react-parser";
import PlayVideo from "../PlayVideo/PlayVideo";
import GetLink from "../functions/GetLink";
import CultureNewsCarousel from "../LatestNewsCarousel/CultureNewsCarousel";
import "./CultureModule.scss"

const CultureModule = ({ list }) => {

  return (
    <div className="culture-list-block">
      {list.map((content, key) => {
        return (
          <Row>
            <Col lg={6}>
              {content.Item_Image &&
                <div className="tile-img-wrapper">
                  <img src={content.Item_Image.url} alt={content.Item_Image.alternativeText + "- block module image"} />
                </div>
              }
            </Col>
            <Col lg={6} className="">
              <div className="content">
                <h4 >{content.Item_Title}</h4>
                {content.Item_Text && <>{parse(content.Item_Text)}</>}
              </div>
            </Col>
          </Row>
        )
      })}
    </div>
  )
}

export default CultureModule
