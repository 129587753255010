import React, {useState, useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './NewsListing.scss'
import { NewsCategories, AllNews } from '../../queries/common_use_query';
import { NewsBaseURL } from '../common/site/utils';
import { Link } from 'gatsby';
import GetGGFXImage from '../common/site/get-ggfx-image';
import moment from "moment";
import GoogleBanner from '../GoogleBanner/GoogleBanner';
import { isMobile } from 'react-device-detect';
import Select from 'react-select';
import $ from 'jquery';
import { parse as Queryparse } from "query-string"
import { useLocation } from "@reach/router"
import PlayVideo from '../PlayVideo/PlayVideo';
import _ from 'lodash';

const postsPerPage = isMobile ? 10 : 9;
let arrayForHoldingPosts = [];

function NewsListing(props) {
    const location = useLocation()
    const { categoryId } = Queryparse(location.search)

    // From popular search results page
    const tabID = location?.state?.communityId

    const { loading: loading, error: error, data: data } = NewsCategories();
    const { loading: news_loading, error: news_error, data: news_data } = AllNews();
    const [newscategory, setNewscat] = useState(tabID || categoryId || '');
    const [mobileonly, setMobileonly] = useState(false);
    const [listnews, setListNews] = useState([]);
    const [isPlay, setPlay] = useState(false);
    const [videourl, setVideourl] = useState(false);

    useEffect(() => {        
        setListNews(news_data && news_data.newsInsights && news_data.newsInsights)
        console.log("🚀 ~ file: NewsListing.js:39 ~ useEffect ~ news_data:", news_data?.length)
    }, [news_data]);

    const [postsToShow, setPostsToShow] = useState([]);
    const [next, setNext] = useState(9);

    useEffect(() => {
      arrayForHoldingPosts = [];
      if ($(window).width() < 767) {
            setMobileonly(true);
      }
    }, []);

    useEffect(() => {
        arrayForHoldingPosts = [];
        var filternews = news_data && news_data.newsInsights && news_data.newsInsights;
        filternews?.map((news, index) => {
        console.log("🚀 ~ file: NewsListing.js:55 ~ filternews?.map ~ news:", news.select_categories)
        })
        if(newscategory) {
            setPostsToShow([]);
            setListNews(filternews?.filter(obj => obj.select_categories?.filter(cat => cat.id == newscategory).length > 0))
        } else {
            setListNews (filternews)
            setNext(isMobile ? 10 : 9);
        }
    }, [newscategory, news_data]);

    const loopWithSlice = (start, end) => {
      const slicedPosts = listnews.slice(start, end);
      arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
      setPostsToShow(arrayForHoldingPosts);
    };
  
    const handleShowMorePosts = () => {
      loopWithSlice(next, next + postsPerPage);
      setNext(next + postsPerPage);
    };
    useEffect(() => {
        listnews && loopWithSlice(0, postsPerPage);
    }, [listnews]);
  
    const ImageRenderList = ({ news, processedImages }) => {
        return (
            <GetGGFXImage imagename={"news-insights.Tile_Image.listimg"} imagesource={news.Tile_Image} fallbackalt={news.Title} imagetransformresult={processedImages} id={news.id}/>    
            )
      }
      var selectvalues = []
      selectvalues.push({ value:'', label: 'All News' } )

      var selectvaluesDesktop = []

      {data && data.newsCategories && data.newsCategories.filter(item => {
        let hasCategory = false;
        news_data?.newsInsights.forEach(element => {
            if(element?.select_categories?.find(cat => cat.Name == item?.Name)) {
                hasCategory = true
            }
        });
        return hasCategory
      }).map(val => {
         selectvalues.push({ value:val.id, label: val.Name } )
         selectvaluesDesktop.push({ value:val.id, label: val.Name } )
    	})}

    return (
        <section className='section-p120 news-listing'>
            <Container>
                <div className='list-head'>
                    <h1>{props.title}</h1>
                    <div className='news-cat'>
                        {mobileonly?
                        <Select options={selectvalues} values={[]} isSearchable={false}
                        classNamePrefix={"select-opt"}
                        className="select-control"
                        defaultValue={{ label: selectvalues.length > 0 && selectvalues[0].label, value: selectvalues.length > 0 && selectvalues[0].value }}
                        onChange={e => setNewscat(e.value)}
                        />                       
                        :
                        <ul>
                            <li><a href="javascript:;" onClick={()=> setNewscat('')}className={!newscategory?'active':""}>All Posts</a></li>
                            {selectvaluesDesktop.map(newscat => {
                                return <>
                                    <li><a href="javascript:;" className={newscat.value == newscategory?'active':""} onClick={()=> setNewscat(newscat.value)}>{newscat.label}</a></li>
                                </>
                            })}
                        </ul>
                        }
                    </div>
                </div>
                <div className='news-listing-wrapper'>
                    <Row>
                    {postsToShow && postsToShow.map((news,index) => {
                        let processedImages = JSON.stringify({});
                        if (news?.imagetransforms?.Tile_Image_Transforms) {
                            processedImages = news.imagetransforms.Tile_Image_Transforms;
                        }

                        const href = !news.Video_URL ? {to:NewsBaseURL+news.URL+'/ '} : {href: "javascript:void(0)"}

                        let categoryNames = ""
                        if(!_.isEmpty(news?.select_categories)) {
                            categoryNames = news.select_categories.map((cat, index) => {
                                return <span key={index}>{cat.Name}{index < news.select_categories.length - 1 ? ", " : ""}</span>
                            })
                        }

                        return <>
                        <Col md={`6`} lg={`4`}>
                            <div className={`${news.Video_URL ? 'video-item-wrap' : '' } news-item-wrap`}>
                                <div className='img-zoom rounded'>
                                    <Link {...href}>
                                        <ImageRenderList processedImages={processedImages}  news={news} />              
                                    </Link>
                                    {news.Video_URL && <a className='btn-play' href="javascript:void(0)" onClick={(e) => {setPlay(true); setVideourl(news.Video_URL)}}><i className="icon icon-play"></i></a>}
                                </div>
                                {categoryNames && <div className='news-cat'>{categoryNames}</div>}
                                <div className={`${!categoryNames ? 'pt-3' : ""} news-title`}><Link {...href}>{news.Title}</Link></div>
                                <div className='news-date'>{moment(news.News_Date).format("Do MMMM YYYY")}</div>
                            </div>
                        </Col>
                        {((index + 1) % 6) === 0  ?
                            <GoogleBanner/>
                        :""}
                        </>
                    })}
                    {postsToShow  && postsToShow.length > 8 && postsToShow.length >= next  &&
                        <a onClick={handleShowMorePosts} class="btn btn-dark load-more">Load More</a>
                    }
                    {isPlay && videourl &&
                        <PlayVideo isOpen={isPlay} stopPlay={setPlay} videoId="" isCloseFunction={setPlay} videourl={videourl} htmlink={""} />
                    }
                    </Row>
                </div>
            </Container>
        </section>
    );
}

export default NewsListing;
