import React from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";

const ScreenLayout = ({ title, classNames, children, finalScreen }) => {
  const [parent] = useAutoAnimate(/* optional config */);

  return (
    <div className={`screen-container ${classNames}`} ref={parent}>
      {finalScreen ? <h1 className="title">{title}</h1> : <h2 className="title">{title}</h2>}
      <div className="screen-contents">{children}</div>
    </div>
  );
};

export default ScreenLayout;
