import * as React from "react"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import FormImg from "../images/form-img.png";
import parse from "html-react-parser"
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useLocation } from "@reach/router"
import { parse as Queryparse } from "query-string"
import { GetPropertyBooking } from '../queries/common_use_query';

//  Forms
    import ContactForm from "../components/forms/contact";
    import BookingForm from "../components/forms/book-a-viewing";
    import SendToFriendForm from '../components/forms/sendfrnd-form';
    import GetMortgageAdvice from '../components/forms/get-mortgage-advice'
    import HomeVisitValuation from "../components/forms/home-visit-valuation";
    import InstantValuation from "../components/forms/instant-valuation";
import DownloadGuideForm from "../components/forms/download-guide-form";
import { Helmet } from "react-helmet";
import ReviewsCarousel from "../components/ReviewsCarousel/ReviewsCarousel";
// 
function FormTemplate(props) {
    const GQLModules = props.GQLModules
    var officeemail = props?.locationState?.officeemail   
    var officename = props?.locationState?.officename  
    var teamemail  = props?.locationState?.teamemail 
    var staffname  = props?.locationState?.staffname 
    let pageTitle = props?.locationState?.pageTitle
    let guideUrl = props?.locationState?.file_url

    // Booking Property
    const location = useLocation();    
    const searchParams = Queryparse(location.search) // => {init: "true"}
    const pid = searchParams.pid
    const {loading:prop_loading, error:prop_error, data:prop_data} = GetPropertyBooking(pid);
    var propertyurl= ""
    var backurl = ""
    var propertyslug = ""
    var searchtype = prop_data && prop_data.properties.length > 0 ? prop_data.properties[0].search_type:"";
    var displayaddress = prop_data && prop_data.properties.length > 0 ? prop_data.properties[0].display_address:"";
    if(prop_data && prop_data.properties.length > 0) {
        propertyurl =   searchtype=="sales"?"/property-for-sale/":"/property-to-rent/"
        backurl =   searchtype=="sales"?"/property-for-sale/in-london":"/property-to-rent/in-london"
        propertyslug =  prop_data.properties[0].slug+'-'+prop_data.properties[0].id+'/';
    }
    // 

    let title = `Get ${pageTitle} Guide.`
    let desc = `<p>Please complete the form below and we will send you a copy of the ${pageTitle} Guide.</p>`

    const isValuationPage = GQLModules.some((Module) => Module.Select_Form === "Home_Visit_Valuation" || Module.Select_Form === "Instant_Valuation")
    const image = GQLModules.filter(Module => Module.image)?.pop()?.image
        
    return (
        <>
            <Header />
            <section className="form-page-wrapper">
                <Container>
                    <Row>
                        <Col lg={5} className="order-lg-1">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className={"form-page-details" + (isValuationPage ? " valuation-form-details": "")}>
                                    <div className="form-page-heading">
                                        <h1>{ (officename) ? 'Contact '+officename: (staffname) ? 'Contact '+staffname : props.title ? props.title : title}</h1>
                                        {parse(props.content ? props.content : desc )}
                                    </div>
                                    {GQLModules.map((Module, i) => {
                                        return (
                                            <div className="form-page-form-details">
                                                {Module.Select_Form === "Contact_Form" && !officeemail && !teamemail &&
                                                    <ContactForm />                                                                                     
                                                }
                                                {Module.Select_Form === "Contact_Form" && officeemail &&
                                                    <ContactForm to_email_id={officeemail} subject="Contact Office"/>                                                                                     
                                                }
                                                {Module.Select_Form === "Contact_Form" && teamemail &&
                                                    <ContactForm to_email_id={officeemail} subject="Team Contact"/>                                                                                     
                                                }
                                                {Module.Select_Form === "Get_Mortgage_Advice" && 
                                                    <GetMortgageAdvice />                                                                                     
                                                }
                                                {Module.Select_Form === "Home_Visit_Valuation" && 
                                                    <HomeVisitValuation />                                                                                     
                                                }
                                                {Module.Select_Form === "Instant_Valuation" && 
                                                    <InstantValuation />                                                                                     
                                                }
                                                {Module.Select_Form === "Book_a_Viewing" && prop_data && prop_data.properties.length > 0 &&
                                                    <BookingForm property_url={location.origin+propertyurl+propertyslug} property_id={prop_data.properties[0].id} property_crm_id={prop_data.properties[0].crm_id} page_url={location.origin+propertyurl+propertyslug} property_title={displayaddress} property_img={prop_data.properties[0].images && prop_data.properties[0].images[0].url} property_address={displayaddress} property_bedrooms={prop_data.properties[0].bedroom} property_bathrooms={prop_data.properties[0].bathroom} searchtype={searchtype} property_price={prop_data.properties[0].price}/>    
                                                }
                                                {Module.Select_Form === "Send_To_Friend" && prop_data && prop_data.properties.length > 0 &&
                                                    <SendToFriendForm property_url={location.origin+propertyurl+propertyslug} property_id={prop_data.properties[0].id} page_url={location.origin+propertyurl+propertyslug} property_title={displayaddress} property_img={prop_data.properties[0].images && prop_data.properties[0].images[0].url} property_address={displayaddress} property_bedrooms={prop_data.properties[0].bedroom} property_bathrooms={prop_data.properties[0].bathroom}/>    
                                                }
                                                {Module.Select_Form === "Download_Guide" &&
                                                    <DownloadGuideForm file_url={guideUrl} title={pageTitle}/>    
                                                }

                                            </div>
                                        )
                                    })}                                    
                                </div>
                            </div>
                        </Col>
                        <Col lg={1} className="order-lg-2"></Col>
                        <Col lg={6} className="order-lg-3 d-lg-block d-none">
                            <div className="form-banner">
                                <img src={image ? image.url : FormImg} />
                            </div>
                        </Col>
                    </Row>
                    {isValuationPage && <ReviewsCarousel />}
                </Container>
            </section>
            <Footer />
        </>
    )
}

export default FormTemplate