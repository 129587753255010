import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import GetLink from "../functions/GetLink"
import "./ImageFullWidthModule.scss"

const ImageFullWidthModule = ({ type }) => {
  const data = useStaticQuery(graphql`
    query GetSellorLetModule {
      glstrapi {
        globalConfig {
          Sell_Or_Let_Modue {
            Module_Banner_Image {
              url
              alternativeText
              url_sharp {
                id
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    height: 560
                    layout: FIXED
                    quality: 90
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
            }
            Module_CTA_Label
            Module_CTA_Link {
              Label
              Primary_URL
              Secondary_URL
              id
            }
            Module_Heading
            Module_Title
            id
          }
          Careers_Full_Width_Module {
            Module_Banner_Image {
              url
              alternativeText
              url_sharp {
                id
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    height: 560
                    layout: FIXED
                    quality: 90
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
            }
            Module_CTA_Label
            Module_CTA_Link {
              Label
              Primary_URL
              Secondary_URL
              id
            }
            Module_Heading
            Module_Title
            id
          }
          Get_Involved_Full_Width_Module {
            Module_Banner_Image {
              url
              alternativeText
              url_sharp {
                id
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    height: 560
                    layout: FIXED
                    quality: 90
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
            }
            Module_CTA_Label
            Module_CTA_Link {
              Label
              Primary_URL
              Secondary_URL
              id
            }
            Module_CTA_Secondary_Link
            Module_Heading
            Module_Title
            id
          }
          book_valuation_full_width_module {
            Module_Banner_Image {
              url
              alternativeText
              url_sharp {
                id
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    height: 560
                    layout: FIXED
                    quality: 90
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
            }
            Module_Heading
            Module_Title
            id
            CTAs {
              CTA_Label
              CTA_Link {
                Primary_URL
              }
            }
          }
        }
      }
    }
  `)

  const module =
    type && type === "Sell_Or_Let_Property_Module"
      ? data.glstrapi.globalConfig.Sell_Or_Let_Modue
      : type === "Join_The_Community_Full_Width_Module"
      ? data.glstrapi.globalConfig.Get_Involved_Full_Width_Module
      : type === "Careers_Full_Width_Module"
      ? data.glstrapi.globalConfig.Careers_Full_Width_Module
      : type === "Book_Valuation_Full_Width_Module" 
      ? data.glstrapi.globalConfig.book_valuation_full_width_module
      : null

  if (!module) return null

  return (
    <div className="img-full-wrapper">
      <img src={module.Module_Banner_Image.url} alt={`${module.Module_Title} - David Astburys`} className="bg-img" />
      <div className="overlay"></div>
      <Container className="img-full-container">
        <h4 className="title">{module.Module_Title}</h4>
        <h2 className="heading">{module.Module_Heading}</h2>
        {module.Module_CTA_Secondary_Link && (
          <Link
            to={module.Module_CTA_Secondary_Link}
            className="btn btn-fill-primary"
          >
            {module.Module_CTA_Label}
          </Link>
        )}
        {module.Module_CTA_Link && (
          <GetLink
            link={module.Module_CTA_Link}
            label={module.Module_CTA_Label}
            className="btn btn-fill-primary"
          />
        )}
          {module.CTAs?.length > 0 && 
            <div className="cta-section">
              {module.CTAs.map(cta => (
                <>
                  {cta.CTA_Secondary_Link ? (
                    <a
                      href={cta.CTA_Secondary_Link}
                      target="_blank"
                      className="btn btn-banner"
                    >
                      {cta.CTA_Label}
                    </a>
                  ) : (
                    <GetLink
                      label={cta.CTA_Label}
                      link={cta.CTA_Link}
                      className="btn btn-banner"
                    />
                  )}
                </>
              ))}
            </div>
          }
      </Container>
    </div>
  )
}

export default ImageFullWidthModule
