export const getCommaSeparatedValue = (val) => {
  let euroUSLocale = Intl.NumberFormat("en-US");
  let commaVal = val.toString().replace(",", "");

  commaVal = Number.isInteger(commaVal)
    ? euroUSLocale.format(parseInt(commaVal)).toString()
    : euroUSLocale.format(parseFloat(commaVal).toFixed(2)).toString();

  return commaVal;
};

export const sort = (a, b, sortType = "asc") => {
  if (a === null || a === undefined) {
    return 1;
  }
  if (b === null || b === undefined) {
    return -1;
  }
  if (sortType === "asc") {
    return a - b;
  } else {
    return b - a;
  }
};
