import React from "react";
import ScreenLayout from "./ScreenLayout";
import { useSelector, useDispatch } from "react-redux";
import { calculatorTypes } from "../constants";
import { setOtherLegalCosts } from "../../../../redux/features/movingCostCalculatorSlice";
import { getCommaSeparatedValue } from "../../../utils/utils";
import CustomCurrencyInput from "../CustomCurrencyInput";

const ConveyancingScreen = () => {
  const dispatch = useDispatch();

  const calculatorType = useSelector((state) => state.movingCostCalculator.calculatorType);
  const buyConveyancingCosts = useSelector(
    (state) => state.movingCostCalculator.buyConveyancingCosts
  );
  const sellConveyancingCosts = useSelector(
    (state) => state.movingCostCalculator.sellConveyancingCosts
  );
  const otherLegalCosts = useSelector((state) => state.movingCostCalculator.otherLegalCosts);

  const onLegalCostsChange = (value) => {
    if (!value) {
      dispatch(setOtherLegalCosts(0));
      return;
    }
    dispatch(setOtherLegalCosts(Number(value)));
  };

  return (
    <ScreenLayout title="Legal Costs" classNames="conveyancing-screen">
      <div className="text-section-container">
        {(calculatorType === calculatorTypes.BUY ||
          calculatorType === calculatorTypes.BUY_SELL) && (
          <div className="text-section">
            <p className="text-label input-label">Conveyancing Costs (Buying)</p>
            <p className="text-value">£{getCommaSeparatedValue(buyConveyancingCosts)}</p>
          </div>
        )}
        {(calculatorType === calculatorTypes.SELL ||
          calculatorType === calculatorTypes.BUY_SELL) && (
          <div className="text-section">
            <p className="text-label input-label">Conveyancing Costs (Selling)</p>
            <p className="text-value">£{getCommaSeparatedValue(sellConveyancingCosts)}</p>
          </div>
        )}
      </div>
      <div className="input-section">
        <label htmlFor="other_legal_costs">Other Legal Costs</label>
        <div class="input-box">
          <span class="prefix">£</span>
          <CustomCurrencyInput
            id="other_legal_costs"
            name="other_legal_costs"
            className="currency-input-field"
            value={otherLegalCosts}
            onValueChange={onLegalCostsChange}
            placeholder={""}
          />
        </div>
      </div>
    </ScreenLayout>
  );
};

export default ConveyancingScreen;
