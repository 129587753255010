import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { Link } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./assets/styles/_index.scss"
import { RecentNews, RecentEvents } from "../../queries/common_use_query"
import GetGGFXImage from "../common/site/get-ggfx-image"
import { NewsBaseURL } from "../common/site/utils"

const CultureNewsCarousel = ({ type, block }) => {
  const {
    loading: loading,
    error: error,
    data: data,
  } = type === "Events_Listing_Module" ? RecentEvents() : RecentNews()

  const isVision = (block === "vision") ? true : false;

  if (!data) return null

  //   function removeTags(str) {
  //     if (str === null || str === "") return false
  //     else str = str.toString()
  //     return str.replace(/(<([^>]+)>)/gi, "")
  //   }

  function limit(string = "", limit = 0) {
    return string.substring(0, limit)
  }

  // Slider settings
  let settings = {
    dots: false,
    arrows: true,
    infinite: data.newsInsights.length > 3,
    speed: 800,
    slidesToShow: isVision ? 2 :3,
    slidesToScroll: 1,
    centerMode: isVision ? true : false,
    centerPadding: isVision ? "30px" : "0px",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: isVision ? 2 :3,
          slidesToScroll: 1,
          infinite: data.newsInsights.length > 3,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: isVision ? 2 :3,
          slidesToScroll: 1,
          infinite: data.newsInsights.length > 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  }
  return (
    <section className={`latest-news-wrapper ${block}`}>
      <Row>
        <Col>
          <Slider className="latest-news-slider" {...settings}>
            {data?.newsInsights?.length > 0 &&
              data.newsInsights
                .slice()
                .sort((a, b) => new Date(b.News_Date) - new Date(a.News_Date))
                .map((item, index) => {
                  let processedImages = JSON.stringify({})
                  if (item?.imagetransforms?.Tile_Image_Transforms) {
                    processedImages =
                      item.imagetransforms.Tile_Image_Transforms
                  }
                  var limtnewsdesc =
                    item.Description.length > 120
                      ? limit(item.Description, 120) + "..."
                      : item.Description

                  return (
                    <>
                      <div className="latest-news-slide text-center">
                        <div className="latest-news-img-zoom">
                          <Link to={NewsBaseURL + item.URL + "/ "}>
                            <GetGGFXImage
                              imagename={"news-insights.Tile_Image.tileimg"}
                              imagesource={item.Tile_Image}
                              fallbackalt={item.Title}
                              imagetransformresult={processedImages}
                              id={item.id}
                            />
                          </Link>
                        </div>
                        <div className="latest-news-details">
                          <div className="latest-news-title">
                            <Link to={NewsBaseURL + item.URL + "/ "}>
                              {item.Title}
                            </Link>
                          </div>
                          <p className="latest-news-desc">{limtnewsdesc}</p>
                        </div>
                      </div>
                    </>
                  )
                })}
          </Slider>
        </Col>
      </Row>
    </section>
  )
}

export default CultureNewsCarousel
