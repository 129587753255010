import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const useCompanyInfo = () => {
  const data = useStaticQuery(graphql`
    query getSociallink {
      glstrapi {
        globalConfig {
          Footer {
            Insta_Link
            LinkedIn_Link
            Twitter_Link
            FB_Link
            id
          }
        }
      }
    }
  `)

  const { Insta_Link, LinkedIn_Link, Twitter_Link, FB_Link } = data?.glstrapi?.globalConfig?.Footer

  return { InstaLink: Insta_Link, LinkedInLink: LinkedIn_Link, TwitterLink: Twitter_Link, FBLink: FB_Link }
}

export default useCompanyInfo