import React from "react";
import { BuySellSVG, BuySVG, SellSVG } from "./SVGs";
import { useSelector, useDispatch } from "react-redux";
import {
  setCalculatorType,
  incrementScreenIndex,
  reset,
  setCalculatorScreens,
  setCurrentScreen,
} from "../../../redux/features/movingCostCalculatorSlice";

import { calculatorTypes, buyScreens, sellScreens, buySellScreens, screen } from "./constants";

const InitialStep = () => {
  const dispatch = useDispatch();

  const currentScreens = useSelector((state) => state.movingCostCalculator.calculatorScreens);

  const setScreen = (screens) =>
    dispatch(setCalculatorScreens(screens)) && dispatch(setCurrentScreen(screens[0]));

  const onSelectType = (type) => {
    dispatch(reset());
    dispatch(incrementScreenIndex());
    dispatch(setCalculatorType(type));
    type === calculatorTypes.BUY && setScreen(buyScreens);
    type === calculatorTypes.SELL && setScreen(sellScreens);
    type === calculatorTypes.BUY_SELL && setScreen(buySellScreens);
    type === calculatorTypes.NONE &&
      dispatch(setCalculatorScreens([])) &&
      dispatch(setCurrentScreen(screen.INITIAL_SCREEN));
  };

  return (
    <div className="initial-step">
      <p className="description">Are you looking to </p>
      <div className="choose-type">
        <div className="type-wrap">
          <div
            className="type type-buy"
            onClick={() => {
              onSelectType(calculatorTypes.BUY);
            }}
          >
            <BuySVG />
          </div>
          <p className="label">Buy only</p>
        </div>
        <div className="type-wrap">
          <div
            className="type type-sell"
            onClick={() => {
              onSelectType(calculatorTypes.SELL);
            }}
          >
            <SellSVG />
          </div>
          <p className="label">Sell only</p>
        </div>
        <div className="type-wrap">
          <div
            className="type type-buy-sell"
            onClick={() => {
              onSelectType(calculatorTypes.BUY_SELL);
            }}
          >
            <BuySellSVG />
          </div>
          <p className="label">Buy and Sell</p>
        </div>
      </div>
    </div>
  );
};

export default InitialStep;
