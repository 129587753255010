import React, { useState } from "react"
import { Link } from "gatsby"
import PlayVideo from "../PlayVideo/PlayVideo"
import GetGGFXImage from "../common/site/get-ggfx-image"

const AreaListingCard = ({ areaguide }) => {
  const [isPlay, setPlay] = useState(false)

  if (!areaguide) return null

  let processedImages = JSON.stringify({})
  if (areaguide?.imagetransforms?.Tile_Image_Transforms) {
    processedImages = areaguide.imagetransforms.Tile_Image_Transforms
  }

  return (
    <>
      <div className="area-card ">
        {areaguide.Video_Link && (
          <a
            className="play-icon"
            href="javascript:void(0)"
            onClick={e => {
              setPlay(true)
            }}
          >
            <i className="icon icon-play"></i>
          </a>
        )}
        <Link
          to={`${areaguide.URL}/ `}
          className="area-card-image rounded img-zoom"
        >
          <GetGGFXImage
            imagename={"areaguides.Tile_Image.tileimg"}
            imagesource={areaguide.Tile_Image}
            fallbackalt={areaguide.Name + " - img"}
            imagetransformresult={processedImages}
            id={areaguide.id}
          />
        </Link>

        <Link to={`${areaguide.URL}/ `} className="area-card-name">
          {areaguide.Name}
        </Link>
      </div>
      {isPlay && (
        <PlayVideo
          isOpen={isPlay}
          stopPlay={setPlay}
          videoId=""
          isCloseFunction={setPlay}
          videourl={areaguide.Video_Link}
          htmlink={""}
        />
      )}
    </>
  )
}

export default AreaListingCard
