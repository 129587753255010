import React from 'react'
import { Accordion, Container, Col } from "react-bootstrap"
import parse from "html-react-parser"
import "./_faq.scss"
function FAQ(props) {
  let theme = props.Theme ? `section-p120 ${props.Theme}` : 'section-m120'
  return (
    <section className={`${theme} faq-section`}>
        <Container className="faq-block-wrapper">
        <Col xl={12} className="justify-content-center text-center m-auto">
            <div className='faq-head'>
            <div className='title-small'>frequently asked questions</div>
            <h2>
                {parse(props.content)}
            </h2>
            </div>
            <div className="accordian-section">
                <Accordion flush>
                    {props.items?.map((accordion, i) => (
                    <Accordion.Item eventKey={i} key={"acoordian" + i}>
                        <Accordion.Header>
                        <h4>{accordion.FAQ_Title}</h4>
                        </Accordion.Header>
                        <Accordion.Body>
                        {parse(accordion.FAQ_Content)}
                        </Accordion.Body>
                    </Accordion.Item>
                    ))}
                </Accordion>
                </div>
                </Col>
        </Container>
    </section>
  )
}

export default FAQ