import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import {
  setCalculatorType,
  setPrevScreen,
  setCurrentScreen,
  setNextScreen,
  resetScreenIndex,
  incrementScreenIndex,
  decrementScreenIndex,
  resetCalculator,
  setCalculatorScreens,
  reset,
} from "../../../redux/features/movingCostCalculatorSlice";
import "./MovingCostCalculator.scss";
import NextStep from "../NextStep";
import InitialStep from "./InitialStep";
import StampDutyScreen from "./screens/StampDutyScreen";
import ConveyancingScreen from "./screens/ConveyancingScreen";
import OtherCostsScreen from "./screens/OtherCostsScreen";
import ResultsScreen from "./screens/ResultsScreen";
import ValuationSurveyScreen from "./screens/ValuationSurveyScreen";
import AgencyFeesScreen from "./screens/AgencyFeesScreen";
import { screen } from "./constants";

const MovingCostCalculator = ({
  calculator,
  id,
  title,
  metattitle,
  image,
  imagetransforms,
  bannertitle,
  description,
}) => {
  useEffect(() => {
    dispatch(reset());
  }, []);

  const [wrapper] = useAutoAnimate(/* optional config */);
  const [parent] = useAutoAnimate(/* optional config */);

  const dispatch = useDispatch();

  const currentScreen = useSelector((state) => state.movingCostCalculator.currentScreen);
  const screenIndex = useSelector((state) => state.movingCostCalculator.screenIndex);
  const currentCalculatorType = useSelector((state) => state.movingCostCalculator.calculatorType);
  const currentScreens = useSelector((state) => state.movingCostCalculator.calculatorScreens);

  const handlePrevScreen = () => {
    if (screenIndex === 0) {
      dispatch(reset());
    } else {
      dispatch(decrementScreenIndex());
      dispatch(setCurrentScreen(currentScreens[screenIndex - 1]));
    }
  };

  const handleNextScreen = () => {
    dispatch(incrementScreenIndex());
    dispatch(setCurrentScreen(currentScreens[screenIndex + 1]));
  };

  console.log("currentScreens", currentScreens);
  console.log("screenIndex", screenIndex);

  return (
    <div
      className={`calculator-container-wrap moving-calculator-wrap ${
        currentScreen === screen.RESULT_SCREEN ? "result-screen-wrap" : ""
      }`}
      ref={wrapper}
    >
      <div
        className={
          "calculator-container " +
          (currentScreen === screen.INITIAL_SCREEN ? "calc-initial-screen" : "")
        }
      >
        {currentScreen !== screen.RESULT_SCREEN && (
          <div className="content">
            <h1 className="title">{bannertitle}</h1>
            {currentScreen === screen.INITIAL_SCREEN && (
              <div className="description">{parse(description)}</div>
            )}
          </div>
        )}
        <form className={"calculator-section"} ref={parent} onSubmit={null}>
          {currentScreen === screen.INITIAL_SCREEN && <InitialStep />}
          {currentScreen === screen.STAMP_DUTY_SCREEN && <StampDutyScreen />}
          {currentScreen === screen.AGENCY_FEES_SCREEN && <AgencyFeesScreen />}
          {currentScreen === screen.CONVEYANCING_SCREEN && <ConveyancingScreen />}
          {currentScreen === screen.OTHER_COSTS_SCREEN && <OtherCostsScreen />}
          {currentScreen === screen.VALUATION_SURVEY_SCREEN && <ValuationSurveyScreen />}
          {currentScreen === screen.RESULT_SCREEN && <ResultsScreen />}
          {screenIndex >= 0 && (
            <div
              className={`screen-change-btns ${
                currentScreen === screen.RESULT_SCREEN ? "final-screen-btns" : ""
              }`}
            >
              {currentScreen !== screen.RESULT_SCREEN &&
                (screenIndex > 0 ? (
                  <div onClick={handlePrevScreen} className="btn btn-fill-primary">
                    Prev
                  </div>
                ) : (
                  <div onClick={() => dispatch(reset())} className="btn btn-fill-primary">
                    Back to Selection
                  </div>
                ))}
              {screenIndex < currentScreens?.length - 1 ? (
                <div onClick={handleNextScreen} className="btn btn-fill-primary">
                  Next
                </div>
              ) : (
                <div onClick={() => dispatch(reset())} className="btn btn-fill-primary">
                  Start Again
                </div>
              )}
            </div>
          )}
        </form>
      </div>
      {currentScreen === screen.RESULT_SCREEN && (
        <NextStep className="d-none d-md-block style-tab" />
      )}
    </div>
  );
};

export default MovingCostCalculator;
