import React from "react";
import ScreenLayout from "./ScreenLayout";
import { useSelector, useDispatch } from "react-redux";
import { calculatorTypes } from "../constants";
import { getCommaSeparatedValue } from "../../../utils/utils";

const ResultsScreen = () => {
  const {
    calculatorType,
    buyPrice,
    buyConveyancingCosts,
    sellPrice,
    sellConveyancingCosts,
    agencyFee,
    stampDutyCosts,
    otherLegalCosts,
    valuationTypeValue,
    removalStorageCosts,
    otherMovingCosts,
  } = useSelector((state) => state.movingCostCalculator);

  const isBuy =
    calculatorType === calculatorTypes.BUY || calculatorType === calculatorTypes.BUY_SELL;
  const isSell =
    calculatorType === calculatorTypes.SELL || calculatorType === calculatorTypes.BUY_SELL;

  let totalValue =
    agencyFee +
    stampDutyCosts +
    otherLegalCosts +
    valuationTypeValue +
    removalStorageCosts +
    otherMovingCosts;

  if (isBuy) totalValue += buyConveyancingCosts;

  if (isSell) totalValue += sellConveyancingCosts;


  return (
    <ScreenLayout title="Your Estimated Moving Costs" classNames="results-screen" finalScreen>
      <div className="results-table buy-results">
        <div className="results-grid">
          <>
            <p className="table-data table-data-heading">Type of Cost</p>
            <p className="table-data table-data-heading">Cost</p>
          </>
          {isBuy && (
            <>
              <p className="table-data">Stamp Duty</p>
              <p className="table-data">£{getCommaSeparatedValue(stampDutyCosts)}</p>
            </>
          )}
          {isSell && (
            <>
              <p className="table-data">Estate Agency Fees</p>
              <p className="table-data">£{getCommaSeparatedValue(agencyFee)}</p>
            </>
          )}
          {isBuy && (
            <>
              <p className="table-data">Conveyancing Costs (Buying)</p>
              <p className="table-data">£{getCommaSeparatedValue(buyConveyancingCosts)}</p>
            </>
          )}
          {isSell && (
            <>
              <p className="table-data">Conveyancing Costs (Selling)</p>
              <p className="table-data">£{getCommaSeparatedValue(sellConveyancingCosts)}</p>
            </>
          )}
          <>
            <p className="table-data">Other Legal Costs</p>
            <p className="table-data">£{getCommaSeparatedValue(otherLegalCosts)}</p>
          </>
          {isBuy && (
            <>
              <p className="table-data">Valuation/Survey Costs</p>
              <p className="table-data">£{getCommaSeparatedValue(valuationTypeValue)}</p>
            </>
          )}
          <>
            <p className="table-data">Removal/Storage Costs</p>
            <p className="table-data">£{getCommaSeparatedValue(removalStorageCosts)}</p>
          </>
          <>
            <p className="table-data">Other Moving Costs</p>
            <p className="table-data">£{getCommaSeparatedValue(otherMovingCosts)}</p>
          </>
          <>
            <p className="table-data table-data-heading">Total Estimated Moving Costs</p>
            <p className="table-data table-data-heading">£{getCommaSeparatedValue(totalValue)}</p>
          </>
        </div>
      </div>
    </ScreenLayout>
  );
};

export default ResultsScreen;
