import React from "react"
import { Container, Col, Row } from "react-bootstrap";
import { graphql, Link, useStaticQuery } from "gatsby"
import GetGGFXImage from '../common/site/get-ggfx-image';
import "./assets/styles/_index.scss"

const OfficeListing = () => {

    const data = useStaticQuery(graphql`
    query OfficeListingDetails {
        glstrapi {
            offices {
                id
                Name
                Office_Address
                Email
                Phone
                Tile_Image {
                    url
                }
                imagetransforms
                URL
            }
        }
        }
    `)

    const offices = data?.glstrapi?.offices

    return (
        <section className="office-listing-wrapper">
            <Container>
                <Row>
                    {
                        offices.map((office, i) => {
                            let processedImages = JSON.stringify({});
                            if (office?.imagetransforms?.Tile_Image_Transforms) {
                                processedImages = office.imagetransforms.Tile_Image_Transforms;
                            }

                            return (
                                <>
                                    {(office.URL != "property-management") &&
                                        <Col lg={4} md={6} key={i} className="office-listing-col">
                                            <div className="office-listing-card">
                                                <div className="office-listing-img-zoom">
                                                    <Link to={office.URL + "/"}>
                                                        <GetGGFXImage
                                                            imagename={"offices.Tile_Image.tileimg"}
                                                            imagesource={office.Tile_Image}
                                                            fallbackalt={office.Name}
                                                            imagetransformresult={processedImages}
                                                            id={office.id}
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="office-listing-card-details text-center">
                                                    <div className="office-listing-title"><Link to={office.URL + "/"}>{office.Name}</Link></div>
                                                    <div className="office-listing-contact"><a href={`tel:${office.Phone}`} className="">{office.Phone}</a></div>
                                                    <div className="office-listing-email"><Link to={`/contact-us/`} state={{ officeid: office.id, officeemail: office.Email, officename: office.Name }} className="">Email Office</Link></div>
                                                    <Link to={office.URL + "/"} className="btn btn-dark">Visit Office Page</Link>
                                                </div>
                                            </div>
                                        </Col>
                                    }
                                </>
                            )
                        })
                    }
                </Row>
            </Container>
        </section>
    )
}

export default OfficeListing