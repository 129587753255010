import React from "react";
import ScreenLayout from "./ScreenLayout";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { setValuationTypeValue } from "../../../../redux/features/movingCostCalculatorSlice";
import { valuationTypeValues } from "../constants";
import { getCommaSeparatedValue } from "../../../utils/utils";

const options = [
  { value: "NONE", label: "None" },
  { value: "SMALL", label: "Mortgage Valuation Only" },
  { value: "MID", label: "Home Buyer Report (Including Mortgage Valuation)" },
  { value: "FULL", label: "Full Survey (Including Mortgage Valuation)" },
];

const ValuationSurveyScreen = () => {
  const dispatch = useDispatch();

  const valuationTypeValue = useSelector((state) => state.movingCostCalculator.valuationTypeValue);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: state.isSelected ? "white" : "#313436",
      backgroundColor: state.isSelected ? "#9B2743" : "white",
      padding: 10,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const onValueChange = (value) => {
    dispatch(setValuationTypeValue(valuationTypeValues[value]));
  };

  return (
    <ScreenLayout title="Valuation/Survey Costs" classNames="valuation-survey-screen">
      <div className="valuation-select">
        <label htmlFor="valuation-select">Type of valuation</label>
        <Select
          options={options}
          styles={customStyles}
          className="valluation-select-container"
          classNamePrefix="valuation-select"
          onChange={(e) => onValueChange(e.value)}
          name="valuation-select"
        />
      </div>

      <div className="text-section-container">
        <div className="text-section">
          <p className="text-label input-label">Valuation/Survey Cost</p>
          <p className="text-value">£{getCommaSeparatedValue(valuationTypeValue)}</p>
        </div>
      </div>
    </ScreenLayout>
  );
};

export default ValuationSurveyScreen;
