import React from "react";
import { Helmet } from "react-helmet";
import "./SocialWall.scss";

const SocialWall = () => {
  return (
    <div className="container social-wall">
      <Helmet>
        <script src="https://apps.elfsight.com/p/platform.js" type="text/javascript" async="true"></script>
      </Helmet>
      <div className="elfsight-app-cf011b54-54bf-4e7f-be67-56fa821caf11"></div>
    </div>
  );
};

export default SocialWall;
