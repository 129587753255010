import React, { useEffect, useState } from "react";
import { getCommaSeparatedValue } from "../utils/utils";
import CustomCurrencyInput from "./MovingCostCalculator/CustomCurrencyInput";

const RentalYieldCalculator = () => {
  const [priceValue, setPriceValue] = useState("1200");
  const [rentValue, setRentValue] = useState("40");

  const [annualRentValue, setAnnualRentValue] = useState("0");
  const [rentalYieldvalue, setRentalYieldvalue] = useState("0");

  useEffect(() => {
    setAnnualRentValue(calculateAnnualRent(rentValue) || 0);
    setRentalYieldvalue(calculateRentalYield(rentValue, priceValue) || 0);
  }, [rentValue, priceValue]);

  const calculateAnnualRent = (value) => {
    let result = value * 12;
    return getCommaSeparatedValue(result);
  };

  const calculateRentalYield = (rent, price) => {
    if (price && rent) {
      if (price == 0 || rent == 0) {
        return 0;
      }
      let result = (rent * 1200) / price;

      return result.toFixed(2);
    }
  };

  const handleOnPriceValueChange = (value, _, values) => {
    if (!value) {
      setPriceValue(0);
      return;
    }
    if (Number.isNaN(Number(value))) {
      return;
    }
    setPriceValue(value);
  };

  const handleOnRentValueChange = (value, _, values) => {
    if (!value) {
      setRentValue(0);
      return;
    }
    if (Number.isNaN(Number(value))) {
      return;
    }
    setRentValue(value);
  };

  return (
    <div className="rental-yield-calculator">
      <div className="input-section-container">
        <div className="input-section">
          <label htmlFor="purchase_price">Purchase Price</label>
          <div class="input-box">
            <span class="prefix">£</span>
            <CustomCurrencyInput
              id="purchase_price"
              name="purchase_price"
              className="currency-input-field"
              value={priceValue}
              onValueChange={handleOnPriceValueChange}
              placeholder={priceValue}
            />
          </div>
        </div>
        <div className="input-section">
          <label htmlFor="monthly_rent">Monthly Rent</label>
          <div class="input-box">
            <span class="prefix">£</span>
            <CustomCurrencyInput
              id="monthly_rent"
              name="monthly_rent"
              className="currency-input-field"
              value={rentValue}
              onValueChange={handleOnRentValueChange}
              placeholder={rentValue}
            />
          </div>
        </div>
      </div>
      {/* <p className="results-text">Results</p> */}
      <div className="result-section-container">
        <div className="result-section">
          <p className="result-title">Annual Rent</p>
          <p className="result-value">£{annualRentValue}</p>
        </div>
        <div className="result-section">
          <p className="result-title">Rental Yield</p>
          <p className="result-value">{rentalYieldvalue}%</p>
        </div>
      </div>
    </div>
  );
};

export default RentalYieldCalculator;
