import React from "react"
import GetLinkHOC from "../functions/GetLinkHOC"
import GetGatsbyImage from "../common/site/get-gatsby-image"

const GridBlock = ({ block, index }) => {
  if (!block) return null

  return (
    <GetLinkHOC
      link={block.Grid_Link}
      className={`block rounded block-${index} img-zoom`}
    >
      {/* <img
        src={block.Grid_Image?.url}
        alt={block.Grid_Image?.alternativeText}
        className="block-img"
      /> */}
      <GetGatsbyImage
        image={block.Grid_Image}
        fallbackalt={block.Grid_Image?.alternativeText}
        className="block-img"
      />
      <div className="cta-section">
        <p className="label">{block.Grid_Title}</p>
        <i className="icon-arrow-right"></i>
      </div>
    </GetLinkHOC>
  )
}

export default GridBlock
