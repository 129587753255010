import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import parse from "html-react-parser"
import SidebarNewsletter from '../SidebarNewsletter/SidebarNewsletter'
import'./StaticContent.scss'
function StaticContent(props) {
    return (
        <section className='section-static-content grey-dual-white'>
            <Container className='intro-head'>
                <h1>{props.title}</h1>
                {parse(props.intro)}
            </Container>
            <Container className='content-wrapper'>
                <Row>
                    <Col lg={`8`} className="inner-content">
                        {parse(props.content)}
                    </Col>
                    <Col lg={`1`} className="divider-col d-none d-lg-block"><span></span></Col>
                    <Col lg={`3`}>
                        <SidebarNewsletter />
                    </Col>
                </Row>

            </Container>
        </section>
    )
}

export default StaticContent