import { Link } from 'gatsby';
import React from 'react';
import { Col } from 'react-bootstrap';
import NewsletterForm from '../forms/newsletter-form';
import './GoogleBanner.scss'
function GoogleBanner() {
    return (
        <Col lg={`12`} className="review-badge-group">
            <div className='review-badge-wrapper'>
                <div className="reviews__google">
                    <div className='badge-head'>Stay in the loop with all the latest news</div>
                </div>
                <div className='badge-actions'>
                    <NewsletterForm />
                    <div className="contact-terms">By clicking Subscribe, you agree to our <Link to="/terms-and-conditions/">Terms</Link> and <Link to="/privacy-policy/">Privacy Policy.</Link></div>
                </div>
            </div>
        </Col>
    );
}

export default GoogleBanner;
