import { zodResolver } from "@hookform/resolvers/zod";
import { Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { scrollToElement, SendMail } from "../common/site/utils";
import { postFormData } from "./api/Api";
import Recaptcha from "./elements/new-recaptcha";
import { downloadGuideSchema } from "./schemas";

import loadable from "@loadable/component"


const FORM_CONFIG = {
  form_name: "Download guide",
  formname: "Download guide",
  email_temp_user: "download_guide_user",
  email_temp_admin: "download_guide_admin",
  email_subject_user: "Download Guide",
  email_subject_admin: "Download Guide",
  event_tracking: "download-guide",
};

const DownloadGuideForm = ({ file_url, title, to_email_id }) => {
  const recaptchaRef = useRef();
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const myRef = useRef();

  const { register, watch, formState, handleSubmit, reset } = useForm({
    resolver: zodResolver(downloadGuideSchema),
  });
  const { errors } = formState;

  const onSubmit = async (data) => {
    const token = await recaptchaRef.current.executeAsync();
    if (token) {
      let postData = { ...data, ...FORM_CONFIG };

      postData.file_url = file_url;
      postData.file_name = title;
      postData.telephone = postData?.telephone?.toString();
      postData.to_email_id = to_email_id ? to_email_id : "";

      recaptchaRef.current.reset();
      postFormData(postData).then(async (apiRes) => {
        await SendMail(postData);

        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "formSubmit",
          formType: "form-" + FORM_CONFIG.event_tracking,
          formId: "form-" + FORM_CONFIG.event_tracking,
          formName: FORM_CONFIG.form_name,
          formLabel: FORM_CONFIG.form_name,
        });

        scrollToElement(myRef.current, 400);
      });
    }
  };

  useEffect(() => {
    // reset form and show success after onSubmit is run
    if (formState.isSubmitSuccessful) {
      reset();
      setShowSuccessMsg(true);
      const element = document.getElementById("form-success-msg");
      element && scrollToElement(element);
      setTimeout(() => setShowSuccessMsg(false), 10000);
    }
  }, [formState, reset]);

  return (
    <form className="guide-form form stbform " onSubmit={handleSubmit(onSubmit)} ref={myRef}>
      {showSuccessMsg && (
        <p className="alert-success" id="form-success-msg">
          Thank you for your interest.
        </p>
      )}
      <div className="form-field-group">
        <label htmlFor="name" className="form-label">
          Name*
        </label>
        <input
          type="text"
          placeholder="Enter your name here"
          {...register("name")}
          className="form__field form-control"
        />
        {errors?.name && <p className="error-msg">{errors?.name.message}</p>}
      </div>
      <div className="guide-form">
        <div className="form-field-group">
          <label htmlFor="phone" className="form-label">
            Telephone Number*
          </label>
          <input
            type="number"
            placeholder="Enter your telephone number here"
            {...register("telephone", { valueAsNumber: true })}
            className="form__field form-control"
          />
          {errors?.telephone && <p className="error-msg">{errors?.telephone.message}</p>}
        </div>
        <div className="form-field-group">
          <label htmlFor="email" className="form-label">
            Email Address*
          </label>
          <input
            type="email"
            placeholder="Enter your email here"
            {...register("email")}
            className="form__field form-control"
          />
          {errors?.email && <p className="error-msg">{errors?.email.message}</p>}
        </div>
        <div className="form-field-group">
        <label htmlFor="homeaddress" className="form-label">
        Current Home Address*
        </label>
        <input
          type="text"
          placeholder="Enter your current home address here"
          {...register("homeaddress")}
          className="form__field form-control"
        />
        {errors?.homeaddress && <p className="error-msg">{errors?.homeaddress.message}</p>}
      </div>
      <div className="form-field-group">
        <label htmlFor="homeaddress" className="form-label">
        Property Address*
        </label>
        <input
          type="text"
          placeholder="Enter the property address"
          {...register("address")}
          className="form__field form-control"
        />
        {errors?.address && <p className="error-msg">{errors?.address.message}</p>}
      </div>
        
      </div>
      <button type="submit" className="btn btn-fill-primary w-100 btn">
        Submit
      </button>
      <div className="contact-terms">
        By clicking Submit you agree to our <Link to="/terms-and-conditions">Terms</Link> and{" "}
        <Link to="/privacy-policy">Privacy Policy</Link>.
      </div>
      <div className="d-none">
        <Recaptcha recaptchaRef={recaptchaRef} />
      </div>
      {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
    </form>
  );
};

export default DownloadGuideForm;
