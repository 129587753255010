import React from 'react';
import { Container } from 'react-bootstrap';
import parse from "html-react-parser"
import GetGatsbyImage from '../common/site/get-gatsby-image';
import './ClientsTrust.scss'
import GetLink from '../functions/GetLink';
import CommonSlider from '../CommonSlider/CommonSlider';
const ClientsTrust = ({ module }) => {
  return (
    <section className='section-p grey-bg client-trust-wrapper'>
      <Container>
            <div className='section-head'>
                <h2>{module.Module_Title}</h2>
                {parse(module.Module_Description)}
            </div>
            <div className='modulecards'>
                <CommonSlider>
                {module.Module_Items && module.Module_Items.map((item) => {
                    return<>
                        <div className='modulecard'>
                            <div className='img-zoom'>
                              <GetGatsbyImage
                              image={item?.Item_Image}
                              fallbackalt={item.Item_Title}
                              />
                            </div>
                            <div className='module-content'>
                                <h3>{item.Item_Title}</h3>
                                {parse(item.Item_Text)}
                                <GetLink label={item.Item_Link_Label} link={item.Item_Link} />
                            </div>
                        </div>
                    </>
                }
                )}
                </CommonSlider>
            </div>
      </Container>
    </section>
  );
}

export default ClientsTrust;
