import * as React from "react"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import Banner from "../components/Banner/Banner"
import GridBlocksModule from "../components/GridBlocksModule/GridBlocksModule"
import ReviewsCarousel from "../components/ReviewsCarousel/ReviewsCarousel"
import ClientsTrust from "../components/ClientsTrust/ClientsTrust"
import LatestNewsCarousel from "../components/LatestNewsCarousel/LatestNewsCarousel"
import FAQ from "../components/FAQ/FAQ"
import StatsListingModule from "../components/StatsListing/StatsListing"
import TeamVideo from "../components/TeamVideo/TeamVideo"
import NewsListing from "../components/NewsListing/NewsListing"
import TeamListing from "../components/NewsListing/TeamListing"
import BannerLanding from "../components/BannerLanding/BannerLanding"
import DownloadGuide from "../components/DownloadGuide/DownloadGuide"
import BookAnAppointment from "../components/BookAnAppointment/BookAnAppointment"
import FeaturedPropertiesCarousel from "../components/FeaturedPropertiesCarousel/FeaturedPropertiesCarousel"
import ImageFullWidthModule from "../components/ImageFullWidthModule/ImageFullWidthModule"
import TileBlock from "../components/TileBlock/TileBlock"
import StaticContent from "../components/StaticContent/StaticContent"
import ImageGallery from "../components/ImageGallery/ImageGallery"
import OfficeListing from "../components/OfficeListing/OfficeListing"
import AreaguidesListing from "../components/AreaguidesListing/AreaguidesListing"
import ValuationLanding from "../components/ValuationLanding/ValuationLanding"
import GetInvolvedFormModule from "../components/GetInvolvedFormModule/GetInvolvedFormModule"
import Calculators from "../components/Calculators/Calculators"
import SocialWall from "../components/SocialWall/SocialWall"
import SocialWidget from "../components/LatestNewsCarousel/SocialWidgetModule"
import BookValuationModule from "../components/BookValuationModule/BookValuationModule"
import CultureModule from "../components/CultureModule/CultureModule"
import CultureReviewModule from "../components/CultureReviewModule/CultureReviewModule"
const IndexPage = props => {
  const GQLPage = props.GQLPage
  const GQLModules = props.GQLModules
  //console.log("GQLModules",GQLModules)

 
  const cultureBlockList = GQLModules.filter(el => el.__typename === "GLSTRAPI_ComponentComponentsCultureBlocks");
  var cultureBlockRender = 0;
  //console.log('GQLModules', GQLModules, cultureBlockList)
  return (
    <div className={`${GQLPage.Page_Class ? GQLPage.Page_Class : ""}`}>
      <Header />
      {GQLPage.Layout === "Landing" || GQLPage.Layout === "Landing_No_Title" ? (
        <BannerLanding
          title={GQLPage.Title}
          heading={GQLPage.Banner_Title}
          description={GQLPage.Banner_Content}
          layout={GQLPage.Layout}
          ctas={GQLPage.Banner_CTA}
          reviews={GQLPage.Banner_Reviews}
        />
      ) : GQLPage.Layout === "Calculator" ? (
        <></>
      ) : GQLPage.Banner_Image ? (
        <Banner
          id={GQLPage.id}
          title={GQLPage.Title}
          metattitle={GQLPage.Meta_Title}
          image={GQLPage.Banner_Image}
          imagetransforms={GQLPage.imagetransforms}
          bannertitle={GQLPage.Banner_Title}
          description={GQLPage.Banner_Content}
          cta={GQLPage.Banner_CTA}
          reviews={GQLPage.Banner_Reviews}
          videos={GQLPage.Banner_Videos}
          layout={GQLPage.Layout}
        />
      ) : (
        ""
      )}
      {GQLModules.map((Module, i) => {
        if(Module.__typename === "GLSTRAPI_ComponentComponentsCultureBlocks"){
          cultureBlockRender = (cultureBlockRender+1);
        }
       
        return (
          <>
            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsTileGridBlocks" && (
              <GridBlocksModule module={Module} />
            )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsWhyClientTrustUsBlock" && (
              <ClientsTrust module={Module} />
            )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsValuationBoxes" && (
              <ValuationLanding module={Module} />
            )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsDownloadGuide" && (
              <DownloadGuide module={Module} pageTitle={GQLPage.Title} />
            )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsVideoBlocks" && (
              <CultureReviewModule module={Module}  />
            )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsStatsListing" && (
              <StatsListingModule module={Module} />
            )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsTileContentBlocks" && (
              <TileBlock
                title={Module.Block_Title}
                heading={Module.Block_Heading}
                content={Module.Block_Content}
                ctas={Module.Block_Links}
                image={Module.Tile_Image}
                imgPosition={Module.Image_Position}
                Video_URL={Module.Video_URL}
              />
            )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGridGallery" && (
              <ImageGallery module={Module} />
            )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              (Module.Select_Module === "Social_Widget_Module" ||
                Module.Select_Module === "Home_Reviews_Widget" ||
                Module.Select_Module === "Reviews_Page_Widget" ) && (
                <SocialWidget Select_Module={Module.Select_Module} />
              )}
              {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              Module.Select_Module === "Book_Valuation_Module" && (
                <BookValuationModule />
              )}
              {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              (Module.Select_Module === "Latest_News_Module" ||
                Module.Select_Module === "Events_Listing_Module") && (
                <LatestNewsCarousel type={Module.Select_Module} />
              )}
            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              Module.Select_Module === "News_Listing_Page" && (
                <NewsListing title={GQLPage.Banner_Title} />
              )}
            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              Module.Select_Module === "Social_Wall_Page" && (
                <SocialWall />
              )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              Module.Select_Module === "Team_Listing_Page" && (
                <TeamListing title={GQLPage.Banner_Title} />
              )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              (Module.Select_Module === "Sell_Or_Let_Property_Module" ||
                Module.Select_Module ===
                  "Join_The_Community_Full_Width_Module" ||
                Module.Select_Module === "Careers_Full_Width_Module" || 
                Module.Select_Module === "Book_Valuation_Full_Width_Module") && (
                <ImageFullWidthModule type={Module.Select_Module} />
              )}

            {Module.__typename === "GLSTRAPI_ComponentComponentsForms" &&
              Module.Select_Form === "Book_An_Appointment_Form" && (
                <BookAnAppointment />
              )}

            {Module.__typename === "GLSTRAPI_ComponentComponentsForms" &&
              Module.Select_Form === "Get_Involved_Form" && (
                <GetInvolvedFormModule />
              )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              Module.Select_Module === "Reviews_Module" && <ReviewsCarousel />}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              Module.Select_Module === "Featured_Properties_Module" && (
                <FeaturedPropertiesCarousel />
              )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              (Module.Select_Module === "Rent_Affordability_Calculator" ||
                Module.Select_Module === "Moving_Cost_Calculator" ||
                Module.Select_Module === "Rental_Yield_Calculator") && (
                <Calculators
                  calculator={Module.Select_Module}
                  id={GQLPage.id}
                  title={GQLPage.Title}
                  metattitle={GQLPage.Meta_Title}
                  image={GQLPage.Banner_Image}
                  imagetransforms={GQLPage.imagetransforms}
                  bannertitle={GQLPage.Banner_Title}
                  description={GQLPage.Banner_Content}
                />
              )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              Module.Select_Module === "Areaguides_Listing_Page" && (
                <AreaguidesListing />
              )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              Module.Select_Module === "Office_Listing_Page" && (
                <OfficeListing />
              )}

            {Module.__typename === "GLSTRAPI_ComponentComponentsFaq" && (
              <FAQ content={Module.FQA_Heading} items={Module.FAQ_List} Theme={Module.FAQ_Theme}/>
            )}

            {Module.__typename === "GLSTRAPI_ComponentComponentsStaticPage" && (
              <StaticContent
                title={GQLPage.Banner_Title}
                intro={GQLPage.Banner_Content}
                content={Module.Static_Content}
              />
            )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsTeamVideoModule" && (
              <TeamVideo module={Module} title={GQLPage.Title}/>
            )}

            {cultureBlockRender === 1 && cultureBlockList && cultureBlockList?.length > 0 && 
              <CultureModule blockList={cultureBlockList} pageData={GQLPage}/>  
            }


          </>
        )
      })}
      <Footer popularsearch={GQLPage.Select_Popular_Search}/>
    </div>
  )
}
export default IndexPage
