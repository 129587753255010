import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import GetLink from "../functions/GetLink"
import "./DownloadGuide.scss"

const DownloadGuide = ({ module, pageTitle }) => {
  return (
    <div className="download-guide-wrapper">
      <div className="download-guide-container rounded">
        <h3 className="title">{module.Download_Guide_Title}</h3>
        <div className="content">
          <p className="description">{module.Download_Guide_Text}</p>
          <div className="vertical-divider d-none d-md-block"></div>
          {module.CTA_Link ? (
            <GetLink
              link={module.CTA_Link}
              label={module.CTA_Label}
              className="cta-text cta"
            />
          ) : module.CTA_Secondary_Link ? (
            <Link to={module.CTA_Secondary_Link} className="cta-text cta" state={{pageTitle: pageTitle, file_url: module?.Upload_Guide?.url}}>
              {module.CTA_Label}
            </Link>
          ) : (
            module.Upload_Guide && (
              <a
                href={module?.Upload_Guide?.url}
                target="_blank"
                className="cta-text cta"
              >
                {module.CTA_Label}
              </a>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default DownloadGuide
