import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./assets/styles/_index.scss"
import GetGatsbyImage from "../common/site/get-gatsby-image"

const ImageGallery = ({ module }) => {
  if (module.Upload_Gallery_Images?.length < 1) return null

  let imgArray = module.Upload_Gallery_Images
  let halfwayThrough = Math.floor(imgArray.length / 2)

  let arrayFirstHalf = imgArray.slice(0, halfwayThrough)
  let arraySecondHalf = imgArray.slice(halfwayThrough, imgArray.length)

  // Slider settings
  let settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5.5,
    slidesToScroll: 1,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: false,
        },
      },
    ],
  }

  let settings2 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5.5,
    slidesToScroll: 1,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: false,
    rtl: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: false,
        },
      },
    ],
  }
  // Slider settings

  return (
    <section className="image-gallery-wrapper section-m">
      <Slider className="image-gallery-slider slider1" {...settings}>
        {arrayFirstHalf.map((item, i) => (
          <div className="image-gallery-slide">
            <div className="image-gallery-wrapper">
              <GetGatsbyImage
                image={item}
                fallbackalt={"image slider-1 image - " + i}
              />
            </div>
          </div>
        ))}
      </Slider>

      <Slider className="image-gallery-slider slider2" {...settings2}>
        {arraySecondHalf.map((item, i) => (
          <div className="image-gallery-slide">
            <div className="image-gallery-wrapper">
              <GetGatsbyImage
                image={item}
                fallbackalt={"image slider-2 image - " + i}
              />
            </div>
          </div>
        ))}
      </Slider>
    </section>
  )
}

export default ImageGallery
