import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import RadioField from './elements/radio'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import $ from "jquery"
import axios from "axios"
import * as qs from "query-string"
import _ from "lodash";
import { OFFICES, VALUATION_OPTIONS, getAreaEmail, getOffice, getPostCode } from "./utils"
import loadable from "@loadable/component"

const LoqateAddress = loadable(() => import('./elements/address-search'))

function MySimpleForm(props) {
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);
  const [enableField, setEnableField] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState("");
  const [radioval, setRadioval] = useState(VALUATION_OPTIONS[0].value);
  const [addressLabel, setAddressLabel] = useState('')

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
    {
      element: "config",
      formname: "Home Visit Valuation",
      form_type: "contact",
      error_text: "Highlighted fields are required",
      success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
      email_temp_user: "home_valuation_user",
      email_temp_admin: "home_valuation_admin",
      email_subject_user:"Home Visit Valuation",
      email_subject_admin:"Home Visit Valuation",
      email_server_func: "home_visit_valuation",
      event_tracking: "contact",
      page_url: "/general-enquiry"
    },
    {
      grpmd: "12",
      label: "Type of Valuation",
      placeholder: "Type of Valuation",
      name: "enquiry",
      id: "enquiry",
      element: "radio",
      required: true,
      type: "select",
      class: "select-box",
      values: VALUATION_OPTIONS
    },
    {
      grpmd: "12",
      label: "Name",
      placeholder: "Enter your name here",
      name: "name",
      type: "text",
      element: "input",
      class:"form__field",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "Email Address",
      placeholder: "Enter your email here",
      name: "email",
      type: "email",
      element: "input",
      class:"form__field",
      required: true,
      patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "Telephone Number",
      placeholder: "Enter your telephone number here",
      name: "telephone",
      type: "text",
      element: "input",
      class:"form__field",
      required: true,
      patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "Current Home Address",
      placeholder: "Enter your current home address here",
      name: "homeaddress",
      type: "text",
      element: "input",
      class:"form__field",
      required: true,
      labelClass: "content_b-18",
    },  
    {
      grpmd: "12",
      label: "Property Address",
      placeholder: "Enter your property address here",
      name: "address",
      type: "text",
      element: "address",
      class:"form__field",
      required: true,
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "",
      placeholder: "Preferred date",
      name: "date",
      type: "datetime",
      element: "input",
      class:"form__field form-control",
      // min: today,
      // max: maxday.toString(),
      required: true,
      patternchk: ""
    },
    {
      grpmd: "12",
      label: "How did you hear about us ?",
      placeholder: "How did you hear about us ?",
      name: "hear",
      id: "hear",
      element: "select",
      required: true,
      type: "select",
      class: "select-box",
      values: ['Recommendation', 'Google', 'Facebook', 'Twitter', 'Others'],
    },
    {
      otherHear: "Others",
      grpmd: "12",
      label: "Please Specify",
      placeholder: "Please specify here",
      name: "specify",
      type: "text",
      element: "input",
      class:"form__field",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "Message",
      placeholder:"Write your message here...",
      name: "message",
      element: "textarea",
      class: "form__field form__field-textarea",
      rows:"5",
      labelClass: "content_b-18"
    },
    {
      grpmd: "12",
      name: "Submit",
      type:"submit",
      element: "button",
      value: "submit",
      labelClass: "content_b-18"
    },

    {
      element: "captcha",
      class: "py-2",
      captchaRef: recaptchaRef
    },
  ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }
  const getselectValue = typeof document !== "undefined" && document.getElementById("react-select-opt");
  const handleselectchange = event => {
    if (event) {
      setSelectedOptions(event.value);
    }
    if(event.value.length > 0){
      getselectValue.classList.remove("react-select-css");
    }
    
    // remove initial empty spaces
    event.value = event.value.trimStart()
    if(fields[8].otherHear === event.value) {
      setEnableField(true)
    } else {
      setEnableField(false)
    }
  }

  useEffect(() => {
    $(".form-field-group input").blur(function(){
      $(".form-field-group input").each(function(){
        if ($(this).is(":invalid")) {
          $(this).closest(".form-field-group").addClass("invalid--field");
        } else {
          $(this).closest(".form-field-group").removeClass("invalid--field");
        }
      })
    });
    // $(".select-option.form-field-group").change(function(){
    //   $(".select-option.form-field-group input[type=hidden]").each(function(){
    //     if ($(this).val() == "") {
    //       $(this).closest(".form-field-group").addClass("invalid--field");
    //     } else {
    //       $(this).closest(".form-field-group").removeClass("invalid--field");
    //     }      
    //   })
    // });

    if (token !== '') {

      const processFromData = async () => {

        formvalues['g-recaptcha-response'] = token;

        const propertyPostcode = getPostCode()
        const office = getOffice(propertyPostcode)

        let formData = new FormData();

        // formvalues['extra'] = JSON.stringify({
        //   message: formvalues.message,
        //   name: formvalues.name
        // });
        formvalues['name'] = formvalues.name;
        formvalues['email_subject_user'] = fields[0].email_subject_user;
        formvalues['email_subject_admin'] = fields[0].email_subject_admin;
        formvalues['valuation_department'] = radioval === "BOTH" ? "SALES" : radioval;
        formvalues['lead_comment'] = radioval === "BOTH" ? "Customer requested both a sales and lettings valuation" : "";
        formvalues['use_area_email'] = getAreaEmail(office, radioval);
        formvalues['enquiry'] = radioval
        formvalues['date'] = formvalues.time + " - " + formvalues['date']

        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(async apiRes => {

          window.grecaptcha.reset()

          // lets send mail
          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })

        });

        const url = typeof window !== 'undefined' ? window.location.href : ''
        if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
          fields[0].formname = 'Get mortgage help';
        }
        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-'+fields[0].event_tracking,
          'formId': 'form-'+fields[0].event_tracking,
          'formName': fields[0].formname,
          'formLabel': radioval + " - " + fields[0].formname
        });

        setShowerror(false);
        setThankyou(true);

        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const getRadio = event => {
    event.target.value = event.target.value.trimStart()
    setRadioval(event.target.value)
  }

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (selectedOptions?.length === 0) {
      getselectValue.classList.add("react-select-css");
    } else{
      getselectValue.classList.remove("react-select-css");
    }

    if (form.checkValidity() === false) {
      $(".form-field-group input").each(function(){
        if ($(this).is(":invalid")) {
          $(this).closest(".form-field-group").addClass("invalid--field");
        } else {
          $(this).closest(".form-field-group").removeClass("invalid--field");
        }
      })
      // $(".select-option.form-field-group input[type=hidden]").each(function(){
      //   if ($(this).val() == "") {
      //     $(this).closest(".form-field-group").addClass("invalid--field");
      //   } else {
      //     $(this).closest(".form-field-group").removeClass("invalid--field");
      //   }
      // })

      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    } 
    else if (selectedOptions.length === 0 && fields[7].label == "How did you hear about us ?") {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;
      json['extra'] = _.omit(json, ["formname", "name", "email", "telephone"])

      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);
      setSelectedOptions([]);

      // reset form
      const form = event.target
      form.reset();
      setStartDate();
      setStartTime();
    }
  };

  return (
    <div className="form stbform common_form_class" id="HomeVisitValuation">
      <div ref={myRef} />

      {showerror && <div className="alert-error">
        <p>{fields[0].error_text}</p>
      </div>}

      <div className="form-wraps input-wraps">


        {showthankyou && <div className="alert-success">
          <p>{fields[0].success_text}</p>
        </div>}
        <Form className="contact-form form-w-label" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>
          <input type="hidden" name="form_name" value={fields[0].formname} />
          <input type="hidden" name="form_type" value={fields[0].form_type} />
          <input type="hidden" name="to_email_id" value={props.to_email_id} />
          <input type="hidden" name="bot-field" />

          {fields.map((field, index) => {
            if ( "input" === field.element && field.otherHear ) {
              return (
                <>
                  {enableField &&
                    <InputField
                      name={field.name}
                      grpmd={field.grpmd}
                      ref={field.ref}
                      type={field.type}
                      fieldClass={field.class}
                      placeholder={field.placeholder}
                      startDate={field.type === 'datetime' ? startDate : ''}
                      setStartDate={field.type === 'datetime' ? setStartDate : ''}
                      startTime={field.type === 'datetime' ? startTime : ''}
                      setStartTime={field.type === 'datetime' ? setStartTime : ''}
                      label={field.label}
                      labelClass={field.labelClass}
                      value={field.value}
                      required={field.required}
                      key={`${field.element}~${index}`}
                      pattern={field.patternchk}
                      handlechange={handlechange}
                    />
                  }
                </>
              );
            }
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  startDate={field.type === 'datetime' ? startDate : ''}
                  setStartDate={field.type === 'datetime' ? setStartDate : ''}
                  startTime={field.type === 'datetime' ? startTime : ''}
                  setStartTime={field.type === 'datetime' ? setStartTime : ''}
                  label={field.label}
                  labelClass={field.labelClass}
                  value={field.value}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handleselectchange}
                  componentprops={props}
                  selectedValue={selectedOptions}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  label={field.label}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  label={field.label}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioField
                  label={field.label}
                  name={field.name}
                  ref={field.ref}
                  values={field.values}
                  labels={field.values}
                  fieldClass={field.class}
                  checked={field.checked}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={getRadio}
                  lastchild={field.lastchild}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ( "address" === field.element ) {
              return (
                <LoqateAddress addressLabel={addressLabel}/>
              )
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                />
              );
            }
          })
          }
        </Form>
      </div>
    </div>
  );
}


const HomeVisitValuation = (props) => (
  <MySimpleForm to_email_id={props.to_email_id} subject={props.subject} staff_name={props.staff_name} formtype={props.formtype} messagelabel={props.messagelabel} messageplaceholder={props.messageplaceholder} />
)

export default HomeVisitValuation