export const OFFICES = [
  {
    officeName: "Crouch End",
    postCodes: [
      "N8",
      "N10",
      "N6",
      "N4",
      "N19",
      "N22",
      "N11",
      "N12",
      "N15",
      "N17",
      "N20",
      "N13",
      "N21",
      "N14",
      "N9",
      "N18",
      "HA0",
      "EN1",
      "EN2",
      "EN3",
      "EN4",
      "EN6",
      "EN7",
      "EN8",
      "E17"
    ],
    email: "n8office@davidastburys.com",
    sale_usearea: "9c537560-5615-47fb-a03b-4998c7afab1a@i.usearea.com",
    let_usearea: "48643ea7-a411-4258-8a8b-4a357b841850@i.usearea.com",
  },
  {
    officeName: "Islington",
    postCodes: [
      "N1",
      "N5",
      "N7",
      "N16",
      "WC1",
      "EC1",
      "NW1",
      "NW5",
      "WC2",
      "W1",
      "EC2",
      "EC3",
      "E1",
      "E2",
      "E3",
      "E9",
      "E14",
      "E8",
      "SE21",
      "SW2",
      "SW4",
      "SW9"
    ],
    email: "N1office@davidastburys.com",
    sale_usearea: "d30372d5-9aa6-4d75-8c80-74c783444c20@i.usearea.com",
    let_usearea: "c0a789f8-f784-4478-8ec0-4c9d0e130cad@i.usearea.com",
  },
  {
    officeName: "Hampstead",
    postCodes: [
      "NW3",
      "NW6",
      "NW2",
      "NW8",
      "W9",
      "W2",
      "W10",
      "W11",
      "N2",
      "N3",
      "NW11",
      "NW4",
      "NW7",
      "NW10",
      "NW9",
      "W6",
      "W8",
      "W12",
      "W14"
    ],
    email: "2fa8d007f5fd42b1bcec7d3c1ed9e2be@in.homeflow.co.uk",
    sale_usearea: "114a6486e8b34b23b08f955aba0e1614@in.homeflow.co.uk",
    let_usearea: "87521d84b4434ac6bfcd6a221c780204@in.homeflow.co.uk",
  }
];

export const VALUATION_OPTIONS = [
  { label: "Sales", value: "SALES" },
  { label: "Lettings", value: "LETTINGS" },
  { label: "Both", value: "BOTH" },
];

export const getPostCode = () => {
  const postcode = sessionStorage.getItem("property_postalcode") || "N8";
  return postcode;
};

export const getOffice = (postcode) => {
  const office = OFFICES.find((office) => office.postCodes.includes(postcode));
  return office || OFFICES[0];
};

export const getAreaEmail = (office, type) => {
  const email =
    type === "BOTH"
      ? `${office.sale_usearea},${office.let_usearea}`
      : type === "SALES"
      ? office.sale_usearea
      : office.let_usearea;
  return email;
};
