import React from "react"
import { Container, Col, Row, Form, Button } from "react-bootstrap"
import "../BookAnAppointment/assets/styles/_index.scss"
import Img from "../../images/get_involved.png"
import { Link } from "gatsby"
import GetInvolved from '../../components/forms/get-involved'
const GetInvolvedFormModule = () => {
  return (
    <section
      className="GetInvolvedFormModule-wrapper book-an-appointment-wrapper section-p"
      id="get-involved"
    >
      <Container>
        <Row className="align-items-center">
          <Col lg={6}>
            <div className="book-img-wrapper">
              <img src={Img} className="img-fluid" />
            </div>
          </Col>
          <Col lg={1}></Col>
          <Col lg={5}>
            <div className="book-details-wrapper">
              <div className="title-text-sm-2">Get Involved</div>
              <h2>Get involved with our community events</h2>
              <p>
                Please reach out if you are a local organisation, initiative, business or group and you want to connect with us.
              </p>
              <GetInvolved />
              {/* <Form className="book-form">
                <Form.Group className="form-input-with-bg">
                  <Form.Control type="text" placeholder="Name" />
                </Form.Group>
                <Form.Group className="form-input-with-bg">
                  <Form.Control type="email" placeholder="Email Address" />
                </Form.Group>
                <Form.Group className="form-input-with-bg">
                  <Form.Control type="text" placeholder="Phone Number" />
                </Form.Group>
                <Button
                  variant=""
                  className="btn-white-outline w-100"
                  type="submit"
                >
                  Submit Details
                </Button>
                <Form.Text className="book-text-sm">
                  By clicking Submit, you agree to our{" "}
                  <Link
                    to="/terms-and-conditions/"
                    className="hover-border-white"
                  >
                    Terms & Conditions
                  </Link>{" "}
                  and{" "}
                  <Link to="/privacy-policy/" className="hover-border-white">
                    Privacy Policy
                  </Link>
                  .
                </Form.Text>
              </Form> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default GetInvolvedFormModule
