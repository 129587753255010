import React from "react";
import ScreenLayout from "./ScreenLayout";
import { useSelector, useDispatch } from "react-redux";
import { calculate, countries, propertyTypes, buyerTypes } from "@starberry/react-calculator-stampduty";
import {
  setStampDutyCosts,
  setBuyPrice,
  setIsBuyToLet,
  setBuyConveyancingCosts,
} from "../../../../redux/features/movingCostCalculatorSlice";
import CustomCurrencyInput from "../CustomCurrencyInput";

const StampDutyScreen = () => {
  const dispatch = useDispatch();

  const buyPrice = useSelector((state) => state.movingCostCalculator.buyPrice);
  const isBuyToLet = useSelector((state) => state.movingCostCalculator.isBuyToLet);

  const getStampDutyTax = (propertyPrice, buyerType) => {
    if (!propertyPrice) return 0;

    const stampDuty = calculate(
      propertyPrice,
      propertyTypes.RESIDENTIAL,
      countries.ENGLAND,
      buyerType ? buyerTypes.INVESTOR : buyerTypes.FIRST_TIME
    );

    return stampDuty?.tax;
  };

  const onInputChange = (value) => {
    const shareurl = typeof window !== 'undefined' ? window.location.href : ''
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Stampduty calculator',
            'formType': shareurl,
            'formId': 'Stampduty calculator',
            'formName': 'Stampduty calculator',
            'formLabel': 'Stampduty calculator'
    });
    
    if (!value) {
      dispatch(setBuyPrice(0));
      dispatch(setBuyConveyancingCosts(600));
      dispatch(setStampDutyCosts(0));
      return;
    }

    dispatch(setStampDutyCosts(getStampDutyTax(value, isBuyToLet)));
    dispatch(setBuyConveyancingCosts(Math.max(Math.ceil(value * 0.003), 600)));
    dispatch(setBuyPrice(Number(value)));
  };

  const onCheckChange = (e) => {
    const ischecked = e.target.checked;
    dispatch(setIsBuyToLet(ischecked));
    dispatch(setStampDutyCosts(getStampDutyTax(buyPrice, ischecked)));
  };

  return (
    <ScreenLayout title="Stamp Duty Costs" className="stamp-duty-screen">
      <div className="input-section">
        <label htmlFor="stampDutyCosts">Price of property you want to buy</label>
        <div class="input-box">
          <span class="prefix">£</span>
          <CustomCurrencyInput
            id="stampDutyCosts"
            name="stampDutyCosts"
            className="stampDutyCosts-input-field"
            value={buyPrice}
            onValueChange={onInputChange}
            placeholder={""}
          />
        </div>
      </div>
      <div className="input-check-section">
        <input
          id="isBuyToLet"
          name="isBuyToLet"
          className="isBuyToLet-field"
          value={isBuyToLet}
          onChange={onCheckChange}
          type="checkbox"
        />
        <label htmlFor="isBuyToLet" className="checkbox-label">
          Property is a buy-to-let or second home
        </label>
      </div>
    </ScreenLayout>
  );
};

export default StampDutyScreen;
