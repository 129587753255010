import { Link } from "gatsby"
import React from "react"
import ReviewSection from "../ReviewSection/ReviewSection"

const NextStep = ({ className }) => (
  <div className={"next-step-section" + " " + className}>
    <p className="title">Are you ready to take the next step?</p>
    <Link to={`/contact`} className="btn btn-fill-primary">
      Contact Us
    </Link>
    <ReviewSection />
  </div>
)

export default NextStep
