import React from "react";
import ScreenLayout from "./ScreenLayout";
import { useSelector, useDispatch } from "react-redux";
import {
  setOtherMovingCosts,
  setRemovalStorageCosts,
} from "../../../../redux/features/movingCostCalculatorSlice";
import CustomCurrencyInput from "../CustomCurrencyInput";

const OtherCostsScreen = () => {
  const dispatch = useDispatch();

  const removalStorageCosts = useSelector(
    (state) => state.movingCostCalculator.removalStorageCosts
  );
  const otherMovingCosts = useSelector((state) => state.movingCostCalculator.otherMovingCosts);

  const onOtherMovingCostsChange = (value) => {
    if (!value) {
      dispatch(setOtherMovingCosts(0));
      return;
    }
    dispatch(setOtherMovingCosts(Number(value)));
  };

  const onRemovalStorageCostsChange = (value) => {
    if (!value) {
      dispatch(setRemovalStorageCosts(0));
      return;
    }
    dispatch(setRemovalStorageCosts(Number(value)));
  };

  return (
    <ScreenLayout title="Other Costs" classNames="other-costs-screen">
      <div className="input-section">
        <label htmlFor="removal_costs">Removal/Storage Costs</label>
        <div class="input-box">
          <span class="prefix">£</span>
          <CustomCurrencyInput
            id="removal_costs"
            name="removal_costs"
            className="currency-input-field"
            value={removalStorageCosts}
            onValueChange={onRemovalStorageCostsChange}
            placeholder={""}
          />
        </div>
      </div>
      <div className="input-section">
        <label htmlFor="other_moving_costs">Other Moving Costs</label>
        <p htmlFor="other_moving_costs">
          (eg. gas service, electrical check, connecting domestic services, settling outstanding
          fees/changes)
        </p>
        <div class="input-box">
          <span class="prefix">£</span>
          <CustomCurrencyInput
            id="other_moving_costs"
            name="other_moving_costs"
            className="currency-input-field"
            value={otherMovingCosts}
            onValueChange={onOtherMovingCostsChange}
            placeholder={""}
          />
        </div>
      </div>
    </ScreenLayout>
  );
};

export default OtherCostsScreen;
