import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "../scss/global.scss"
import { graphql } from "gatsby"
import CommonTemplate from "./common-template"
import FormTemplate from "./form-template"
import SEO from "../components/Seo/seo"
const DefaultTemplate = props => {
  const GQLPage = props.data.glstrapi?.article
  const GQLModules = props.data.glstrapi?.article?.Modules
  const locationState = props.location.state
  if (GQLPage?.Layout === "Form") {
    return (
      <>
        <SEO
          title={GQLPage.Meta_Title ? GQLPage.Meta_Title : GQLPage.Title}
          description={GQLPage.Meta_Description}
        />
        <FormTemplate
          title={GQLPage.Banner_Title}
          content={GQLPage.Banner_Content}
          GQLModules={GQLModules}
          locationState={locationState}
        />
      </>
    )
  } else {
    return (
      <>
        <SEO
          title={GQLPage?.Meta_Title ? GQLPage?.Meta_Title : GQLPage?.Title}
          description={GQLPage?.Meta_Description}
        />
        <CommonTemplate
          GQLPage={GQLPage}
          GQLModules={GQLModules}
          // locationState={locationState}
        />
      </>
    )
  }
}
export default DefaultTemplate
export const pageQuery = graphql`
  query DefaultQuery($articleId: ID!) {
    glstrapi {
      article(id: $articleId, publicationState: LIVE) {
        id
        Title
        Meta_Title
        Meta_Description
        Banner_Title
        Banner_Content
        Layout
        Select_Popular_Search
        imagetransforms
        Page_Class
        Banner_Reviews
        Banner_Image {
          url
          alternativeText
        }
        Banner_Videos {
          url
        }
        Banner_CTA {
          CTA_Label
          CTA_Link {
            Primary_URL
          }
          CTA_Secondary_Link
        }
        Modules {
          ... on GLSTRAPI_ComponentComponentsGlobalModules {
            id
            __typename
            Select_Module
          }
          ... on GLSTRAPI_ComponentComponentsForms {
            __typename
            id
            Select_Form
            image {
              url
            }
          }
          ... on GLSTRAPI_ComponentComponentsVideoBlocks {
            __typename
            id
            Video_Thumbnail {
              url
              url_sharp {
                id
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 688
                    layout: FIXED
                    quality: 90
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
            }
            Youtube_Video_URL
          }
          ... on GLSTRAPI_ComponentComponentsStaticPage {
            __typename
            id
            Static_Content
          }
          ... on GLSTRAPI_ComponentComponentsFaq {
            __typename
            id
            FQA_Heading
            FAQ_List {
              FAQ_Content
              FAQ_Title
            }
            FAQ_Theme
          }
          ... on GLSTRAPI_ComponentComponentsTeamVideoModule {
            id
            __typename
            Module_Title
            Module_Content
            Video_Blocks {
              Youtube_Video_URL
              Video_Thumbnail {
                url
                alternativeText
                url_sharp {
                  id
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      width: 448
                      height: 360
                      layout: FIXED
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsGridGallery {
            __typename
            id
            Upload_Gallery_Images {
              url
              alternativeText
              url_sharp {
                id
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 320
                    layout: FIXED
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsValuationBoxes {
            __typename
            id
            Box_Items {
              Box_CTA_Label
              Box_CTA_Link {
                Label
                Primary_URL
                Secondary_URL
              }
              Box_Description
              Box_Title
              id
              Box_Image {
                url
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsTileContentBlocks {
            __typename
            id
            Block_Content
            Block_Heading
            Block_Links {
              CTA_Label
              CTA_Secondary_Link
              id
              CTA_Link {
                Label
                Primary_URL
                Secondary_URL
                id
              }
            }
            Block_Title
            Image_Position
            Video_URL
            Tile_Image {
              url
              alternativeText
              url_sharp {
                id
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 688
                    layout: FIXED
                    quality: 90
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsDownloadGuide {
            __typename
            id
            CTA_Label
            Download_Guide_Text
            Download_Guide_Title
            CTA_Link {
              Label
              Secondary_URL
              Primary_URL
              id
            }
            CTA_Secondary_Link
            Upload_Guide {
              url
            }
          }

          ... on GLSTRAPI_ComponentComponentsWhyClientTrustUsBlock {
            id
            __typename
            Module_Title
            Module_Description
            Module_Items {
              Item_Title
              Item_Text
              Item_Link_Label
              Item_Link {
                Primary_URL
                Secondary_URL
              }
              Item_Image {
                url
                url_sharp {
                  id
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      width: 448
                      height: 240
                      layout: FIXED
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
                alternativeText
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsStatsListing {
            __typename
            id
            Stats_Description
            Stats_Heading
            Stats_Theme
            Stats_List {
              Stats_Caption
              Stats_Icon_Class
              Stats_Link_Label
              Stats_Prefix
              Stats_Postfix
              Stats_Value
              id
              Stats_Link {
                Label
                Primary_URL
                Secondary_URL
                id
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsTileGridBlocks {
            __typename
            id
            Tile_Grid_Heading
            Tile_Grid_Title
            Bg_Color
            Tile_Grids {
              Grid_CTA_Label
              Grid_Title
              id
              Grid_Image {
                url
                alternativeText
                url_sharp {
                  id
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      width: 448
                      layout: FIXED
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
              Grid_Link {
                Label
                Primary_URL
                Secondary_URL
                id
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsCultureBlocks {
            id
            __typename
            Title
            Description
            Content
            Collection
            Video
            Sub_Title
            Image {
              alternativeText
              url
            }
            BlockLink : Block_Links {
              CTA_Label
              CTA_Link {
                Primary_URL
                Secondary_URL
              }
            }
            List_Block {
              Item_Link_Label
              Item_Text
              Item_Title
              Item_Link {
                Primary_URL
                Secondary_URL
              }
              Item_Image {
                alternativeText
                url
              }
            }
          }
        }
      }
    }
  }
`
