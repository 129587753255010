import React, { useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import parse from "html-react-parser"
import GetGatsbyImage from '../common/site/get-gatsby-image';
import PlayVideo from "../PlayVideo/PlayVideo";
import './TeamVideo.scss'
import CommonSlider from '../CommonSlider/CommonSlider';
import ReviewSection from '../ReviewSection/ReviewSection';
const TeamVideo = ({ module , title }) => {
    const [isPlay, setPlay] = useState(false);
    const [videourl, setVideourl] = useState(false);
    const [videotour, setVideoTour] = useState(false);
    return (
        <section className={`section-p120 team-video-block ${title === "customer reviews" ? 'padding_top' : ""}`}>
            <Container>
                <div className="justify-content-center text-center video-header row">
                    <Col lg={8}>
                        <h2>{module.Module_Title}</h2>
                        {module.Module_Content &&
                            parse(module.Module_Content)
                        }
                    </Col>
                </div>
                <CommonSlider>
                    {module.Video_Blocks && module.Video_Blocks.map((item, index) => {
                        return <>
                            <div className='video-block-wrapper'>
                                <div className='video-img rounded img-zoom'>
                                    <GetGatsbyImage
                                        image={item?.Video_Thumbnail}
                                        fallbackalt={`staff video - `+index}
                                    />
                                    <a className='btn-play' href="javascript:void(0)" onClick={(e) => { setPlay(true); setVideourl(item.Youtube_Video_URL); setVideoTour(false); }}><i className="icon icon-play"></i></a>
                                </div>
                            </div>
                        </>
                    }
                    )}
                </CommonSlider>
                {title === "customer reviews" ?
                <ReviewSection title={title} />
                : null}
            </Container>
            {isPlay && videourl &&
                <PlayVideo isOpen={isPlay} stopPlay={setPlay} videoId="" isCloseFunction={setPlay} videourl={videourl} htmlink={""} />
            }
        </section>
    );
}

export default TeamVideo;
