import React, { useState, useEffect, useRef } from "react"
import { Container, Col, Row } from "react-bootstrap";
import parse from "html-react-parser";
import $ from "jquery";
import PlayVideo from "../PlayVideo/PlayVideo";
import GetLink from "../functions/GetLink";
import CultureNewsCarousel from "../LatestNewsCarousel/CultureNewsCarousel";
import CultureListBlock from "./CultureListBlock";
import CulturePartnerList from "./CulturePartnerList";
import ReviewsCarouselCulture from "../ReviewsCarousel/ReviewsCarouselCulture";
import "./CultureModule.scss"

const CultureModule = ({ pageData, blockList }) => {
  const [isPlay, setPlay] = useState(false);
  const [videourl, setVideourl] = useState(false);
  const [scroll, setScroll] = useState(false)
  const myRef = useRef([])

  const handleScroll = (id) => { 
    $('html, body').animate({
      scrollTop: $(`#${id}`).offset().top - 150
    }, 1000);
   
  };

  useEffect(() => {
    if (window) {
      $(window).scroll(function () {
        var windscroll = $(window).scrollTop();
        if (windscroll >= 100) {
          $('.section-block-list').each(function (i) {
            if ($(this).position().top <= windscroll + 200) {
              $('.menus li.active').removeClass('active');
              $('.menus li').eq(i).addClass('active');
            }
          });
        } else {
          $('.menus li.active').removeClass('active');
          $('.menus li:first').addClass('active');
        }
      }).scroll();

      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 500)
      })
    }
  })

  
  
  console.log('GQLModules', pageData)
  return (
    <section className="culture-block tile-block-wrapper section-m">
      <Container>
        <Row>
          <Col lg={3}>
            <div className={`menus sticky-top scrolled`}>
              <ul>
                {blockList.map((menu, key) => {
                  return (
                    <li key={key} onClick={() => { handleScroll(menu.id) }}>  {menu.Title}  </li>
                  )
                })}
              </ul>
            </div>
          </Col>
          <Col lg={9} className="">
            <section className="content tile-block-details-wrapper">
              {blockList.map((content, key) => {
                return (
                  <div key={key} className="section-block-list" id={content.id} >
                    <h4 >{content.Title}</h4>

                    {content.Description && <>{parse(content.Description)}</>}
                    {content.Image &&
                      <div className="tile-img-wrapper">

                        <img src={content.Image.url} alt={content.Image.alternativeText + "- block module image"} />
                        {(content.Video || content.Video) && (
                          <a onClick={(e) => { setVideourl(content.Video); setPlay(true); }}><i className="icon icon-play"></i></a>
                        )}
                      </div>
                    }
                    {content.List_Block &&
                      <CultureListBlock list={content.List_Block} />
                    }
                    <div className="desc">
                    {content.Content && <>{parse(content.Content)}</>}

                    </div>
                   
                    {content.BlockLink?.length > 0 && (
                      <div className="d-flex align-items-center justify-content-left tile-block-link-wrapper">
                        {content.BlockLink.map((cta) => (
                          <>
                            {cta.Secondary_URL ? (
                              <a
                                href={cta.Secondary_URL}
                                target="_blank"
                                className="tile-block-link"
                              >
                                {cta.CTA_Label}
                              </a>
                            ) : (
                              <GetLink
                                label={cta.CTA_Label}
                                link={cta.CTA_Link}
                                className="tile-block-link"
                              />
                            )}
                          </>
                        ))}
                      </div>
                    )}
                    {content.Collection === "Visions" &&
                      <CultureNewsCarousel type={"Events_Listing_Module"} block="vision"/>
                    }
                    {content.Collection === "Communities" &&
                      <CultureNewsCarousel type={"Communities"} block="communities" />
                    }
                    {content.Collection === "Partners" &&
                      <CulturePartnerList />
                    }
                    {content.Collection === "Reviews" &&
                      <ReviewsCarouselCulture />
                    }

                  </div>

                )
              })}
            </section>
            {isPlay && videourl && (
              <PlayVideo
                isOpen={isPlay}
                stopPlay={setPlay}
                videoId=""
                isCloseFunction={setPlay}
                videourl={videourl}
                htmlink={""}
              />
            )}

          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default CultureModule
