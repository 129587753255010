import React, { useState } from "react"
import { Container } from "react-bootstrap"
import "./assets/styles/_index.scss"
import parse from "html-react-parser"
import { Tab, Tabs } from "react-bootstrap"
import RentAffordabilityCalculator from "./RentAffordabilityCalculator"
import GetGGFXImage from "../common/site/get-ggfx-image"
import { Link } from "gatsby"
import RentalYieldCalculator from "./RentalYieldCalculator"
import MovingCostCalculator from "./MovingCostCalculator/MovingCostCalculator"
import NextStep from "./NextStep"

const Calculators = props => {
  const {
    calculator,
    id,
    title,
    metattitle,
    image,
    imagetransforms,
    bannertitle,
    description,
  } = props

  let processedImages = JSON.stringify({})
  if (imagetransforms?.Banner_Image_Transforms) {
    processedImages = imagetransforms.Banner_Image_Transforms
  }

  return (
    <>
      <div className="calculator-wrapper">
        {/* <img src={image.url} alt="" className="calculator-bg-img" /> */}
        <GetGGFXImage
          imagename={"articles.Banner_Image.clacBanner"}
          imagesource={image}
          fallbackalt={metattitle ? metattitle : bannertitle}
          imagetransformresult={processedImages}
          id={id}
          className="calculator-bg-img"
        />
        {calculator === "Moving_Cost_Calculator" ? (
          <MovingCostCalculator {...props} />
        ) : (
          <div className="calculator-container-wrap">
            <div className="calculator-container">
              <div className="content">
                <h1 className="title">{bannertitle}</h1>
                <div className="description">{parse(description)}</div>
              </div>
              <div className="calculator-section">
                {calculator === "Rent_Affordability_Calculator" && (
                  <RentAffordabilityCalculator />
                )}
                {calculator === "Rental_Yield_Calculator" && (
                  <RentalYieldCalculator />
                )}
              </div>
            </div>
            <NextStep className="d-none d-md-block style-tab" />
          </div>
        )}
      </div>
      <NextStep className="d-block d-md-none style-mobile" />
    </>
  )
}

export default Calculators
